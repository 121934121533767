import { Component, Inject, OnDestroy, OnInit,  ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, Store } from '@ngrx/store';
import { Observable, Subscription, Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { LocationsStoreSelectors, PatientsStoreActions, PatientsStoreSelectors, PatientStoreEntity, RootStoreState } from 'src/app/root-store';
import { LANGUAGES_LIST, USSTATE_LIST } from 'src/app/shared/enums';
import { CARD_DATA, ICardData, IFlipEvent } from 'src/app/shared/models';
import * as _ from 'lodash';

import { debounceTime} from 'rxjs/operators';

import {
    ContactAddressDto2,
    IPatientEmailDto,
    IPatientPhoneDto,
    PatientEmailDto,
    PatientPhoneDto,
    SettingsClient,
    GenderEnum,
    ProviderClient,
    ProviderDto3,
    PatientClient
} from 'src/app/shared/services/api.service';

import { PATIENT_TITLES } from 'src/app/shared/enums/PatientTitleType';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import * as moment from 'moment';

import { InternalUserStoreSelectors } from 'src/app/root-store/internal-user-store';
import { DialogComponent } from 'src/app/elements/dialog/dialog.component';
import { MatDialog } from '@angular/material';
import { WarningDialogComponent } from './warning-dialog/warning-dialog.component';
import { FetchAllParameter } from "@shared/services/client/client.model";
import { ProviderClientService } from '@shared/services/client/provider-client.service';
import { FutureMessagesDialogComponent } from '../../../../dialogs/future-messages-dialog/future-messages-dialog.component';

export enum PHONE_TYPE {
  mobilePhone = 'mobilePhone',
  alternatePhone1 = 'alternatePhone1',
  alternatePhone2 = 'alternatePhone2'
}

@Component({
  selector: 'app-basic-details-back',
  templateUrl: './basic-details-back.component.html',
  styleUrls: ['./basic-details-back.component.css'],
})
export class BasicDetailsBackComponent implements OnInit, OnDestroy {
  private includeLanguages: string[] = ['en', 'es'];
  private isFlippedToFront: boolean = false;
  /** Storage for the incoming model; form is recasted back onto model before sent to API for updating */
  patient: PatientStoreEntity;
  /** Primary FormGroup for editing the patient */
  patientFormGroup: FormGroup;
  LANGUAGES_LIST = _.pickBy(LANGUAGES_LIST, (value, key) => {
    return this.includeLanguages.includes(key);
  });;
  isWorking$: Observable<boolean> = this._store$.select(PatientsStoreSelectors.selectPatientsIsLoading);
  isWorking: boolean = false;
  error$: Observable<boolean> = this._store$.select(PatientsStoreSelectors.selectPatientsError);
  locations$: Observable<{ value: number; label: string }[]> = this._store$
    .select(LocationsStoreSelectors.selectAllLocations)
    .pipe(map((locations) => locations.filter(location => location.isActive).map((location) => ({ value: location.id, label: location.shortName }))));
  USSTATE_LIST = USSTATE_LIST;
  /** OnDestroy observable for takeUntil convenience */
  private _destroy$: Subject<boolean> = new Subject<boolean>();

  mdclAlrtArr:any[] = [];
  filterMdclAlrtArr:any[] = [];
  titleArr: { name: string;}[] = Object.entries(PATIENT_TITLES).map(([key, value]) => ({
		name: value.name,
	  }));

  filterTitleArr:any[];
  phonemask: any = ['(', /[0-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  phoneTypes = PHONE_TYPE;

  users$: Observable<{ value: string; label: string }[]> = this._store$.select(InternalUserStoreSelectors.selectAllInternalUsers)
  .pipe(map((users) => users.filter(users => users.isActive).map((users) => ({ value: users.id, label: `${users.firstName} ${users.lastName}` }))));

  dialog: DialogComponent;
  warningDialogOpen:boolean = false;
  notificationList: any = {}
  saveBtnClicked:boolean = false;
  Gender = GenderEnum;
  providers: ProviderDto3[] = [];

  constructor(
    @Inject(CARD_DATA) public data: ICardData,
    private _fb: FormBuilder,
    private _store$: Store<RootStoreState.State>,
    private _actions$: ActionsSubject,
    private settingsClient: SettingsClient,
    public matDialog: MatDialog,
    private _providerClientService: ProviderClientService,
    private _providerClient: ProviderClient,
    private _patientClient: PatientClient
    ) {
    this.dialog = new DialogComponent(matDialog);
    this.data.backgroundColor = '#eaf6fa';
    this.setFormDefaults();
  }

  ngOnInit() {
    this.data.incoming.pipe(takeUntil(this._destroy$)).subscribe((patient: PatientStoreEntity) => {
      this.patient = patient;
      //strip out null properties as patchValue() will throw an error on them
      const defaultPhone: IPatientPhoneDto = {
        id: null,
        number: null,
        extension: null,
        phoneType: null,
        isPrimary: null
      };

      const defaultEmail: IPatientEmailDto = {
        id: null,
        emailType: null,
        emailAddress: null
      };

      let patchValues = {
        ...patient,
        primaryEmail: patient.primaryEmail || new PatientEmailDto(defaultEmail),
        mobilePhone: patient.mobilePhone || new PatientPhoneDto(defaultPhone),
        alternatePhone1: patient.alternatePhone1 || new PatientPhoneDto(defaultPhone),
        alternatePhone2: patient.alternatePhone2 || new PatientPhoneDto(defaultPhone),
        dob: moment(patient.dob).isValid() == true ? moment(patient.dob).utc().format('MM/DD/YYYY') : patient.dob
      };
      Object.keys(patchValues).forEach((key) => !patchValues[key] && delete patchValues[key]);
      this.patientFormGroup.patchValue(patchValues);
      this.patientFormGroup.markAllAsTouched();

      this.getMedicalAlert();
      this.filterTitleArr = this.titleArr;
      this.isFlippedToFront = false;

      let params: FetchAllParameter[] = [
        { value: null },
        { value: null },
        { value: true },
        { value: 20 },
        { isPage: true },
      ];

      this._providerClientService.fetchAll(
        this._providerClient.provider_GetProviders,
        params,
        ProviderDto3
      ).subscribe(resp => {
        this.providers = resp.filter(provider => provider.isActive);
      });

    });
  }

  ngAfterViewInit() {
		setTimeout(() => {
      this.searchMedicalAlert();
      this.titleAutoComplete();
      this.checkElemmentEditOnNotification();
		}, 500);
	}

  /**
   * Build primary FormGroup using FormBuilder
   */
  setFormDefaults() {
    this.patientFormGroup = this._fb.group({
      title: [],
      id: [],
      accountNumber: [],
      patientStatusId: [],
      preferredLocationId: [null, Validators.required],
      preferredProviderId: [],
      preferredCaseManagerId: [],
      firstName: [null, Validators.required],
      middleName: [],
      lastName: [null, Validators.required],
      nickname: [],
      languageKey: ['en', Validators.required],
      gender: [null, Validators.required],
      dob: [],
      employerName: [],
      preferredContactMethod: [],
      optOut: [false],
      ssn: [],
      comments: [],
      primaryAddress: this._fb.group({
        id: [],
        addressType: [],
        addressLine1: [],
        addressLine2: [],
        city: [],
        state: [null, Validators.maxLength(2)],
        zip: [null, Validators.maxLength(10)],
        eTag: [],
      }),
      mobilePhone: this._fb.group({
        id: [],
        phoneType: [],
        number: [],
        extension: [],
        eTag: [],
        isPrimary: []
      }),
      alternatePhone1: this._fb.group({
        id: [],
        phoneType: [],
        number: [],
        extension: [],
        eTag: [],
        isPrimary: []
      }),
      alternatePhone2: this._fb.group({
        id: [],
        phoneType: [],
        number: [],
        extension: [],
        eTag: [],
        isPrimary: []
      }),
      primaryEmail: this._fb.group({
        id: [],
        emailType: [],
        emailAddress: [null, Validators.email],
        eTag: [],
      }),
      cleaningRecall: [],
      school: [],
      medicalAlert: [],
      hobbiesInterests: [],
      loyaltyCard: [],
      hipaaSigned: [null],
      consentSigned: [null],
      contractSigned: [null],
      medicaidId: [],
      emergencyContactInfo: [],
      payoffDue: [],
      paymentAmountDue: [],
      createdWhen: [],
      createdBy: [],
      updatedWhen: [],
      updatedBy: [],
      contactETag: [],
      eTag: [],
      specialInsuranceId: [],
      employeeId: [],
      licenseId: [],
      isSecureAccess: []
    });
  }

  ngOnDestroy() {
    this._destroy$.next(true);
  }

  /**
   * Flip card returning to the front without further action
   */
  cancel() {
    this.data.flip.next(<IFlipEvent>{ payload: null, side: this.data.side });
  }

  /**
   * Save patient including any changes made via the form
   */

  saveOnMouseDn(evt){
    if (evt.which == 1) {
      this.saveBtnClicked = true;
      if(!this.warningDialogOpen){
        this.saveDetails();
      }

    }
  }


  saveDetails() {
      this.patient = { ...this.patient, ...this.patientFormGroup.value }; //recasting form values onto patient object, this will future proof if new properties are added to Patient

      if (!moment(this.patient.dob).isValid()) {
        this.patient.dob = null;
      }

      this.patient.mobilePhone.number = this.patient.mobilePhone.number != null ? this.patient.mobilePhone.number.replace(/\D/g, "") : null;
      this.patient.alternatePhone1.number = this.patient.alternatePhone1.number != null ? this.patient.alternatePhone1.number.replace(/\D/g, "") : null;
      this.patient.alternatePhone2.number = this.patient.alternatePhone2.number != null ? this.patient.alternatePhone2.number.replace(/\D/g, "") : null;

      this._store$.dispatch(PatientsStoreActions.UpdateRequest({ patient: this.patient }));

      this._actions$
        .pipe(ofType(PatientsStoreActions.UpdateSuccess), take(1), takeUntil(this._destroy$)) //listen for success then flip
        .subscribe((result) => {
          this.refresh();
        });
  }

  /**
   * Return the current date as an ISO string.
   */
  getDate() {
    return new Date(Date.now()).toISOString();
  }

  /**
   * Set a FormControl that is part of the primary FormGroup's value
   * @param controlName Name of FormControl as a part of the FormGroup
   * @param value New value which to set FormControl's value
   */
  setControlValue(controlName: string, value: any) {
    this.patientFormGroup.get(controlName).setValue(value);
  }


  getMedicalAlert(){
		this.settingsClient.settings_GetMedicalAlerts(null).subscribe(
			(res) => {
				this.mdclAlrtArr = res.filter(item => {
					return item.isActive == true
				})
			},
			(err) => {
				console.log(err)
			}
		)
  }

  searchMedicalAlert() {

      this.patientFormGroup.controls['medicalAlert'].valueChanges.pipe(
			debounceTime(500),
			map((userInput: any) => userInput)).subscribe(res => {

        let searchkeyword: any = res.toLowerCase();
				if (searchkeyword.length > 0) {
          this.filterMdclAlrtArr = this.mdclAlrtArr.filter(option => option.name.toLowerCase().includes(searchkeyword));
          if(this.filterMdclAlrtArr.length == 0){
            this.filterMdclAlrtArr = this.mdclAlrtArr;
          }
				} else {
					this.filterMdclAlrtArr = [];
				}
      })
  }

  titleAutoComplete() {

    this.patientFormGroup.controls['title'].valueChanges.pipe(
      debounceTime(500),
      map((userInput: any) => userInput)).subscribe(res => {

        let searchkeyword: any = res.toLowerCase();
        if (searchkeyword.length > 0) {
          this.filterTitleArr = this.titleArr.filter(option => option.name.toLowerCase().includes(searchkeyword));
          if (this.filterTitleArr.length == 0) {
            this.filterTitleArr = this.titleArr;
          }
        } else {
          this.filterTitleArr = this.titleArr;
        }
      })
  }

  dateMask() {
		const autoCorrectedDatePipe = createAutoCorrectedDatePipe('mm/dd/yyyy');
		return { mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/], keepCharPositions: true, pipe: autoCorrectedDatePipe };
  }

  dateValueChange(evt){
    this.patientFormGroup.patchValue({
      dob:  moment(evt.value).format("MM/DD/YYYY")
    })
  }

  phoneIsPrimaryChanged(type: string, val: any) {
    if (!val || !val.checked) return;

    if (type != PHONE_TYPE.mobilePhone) {
      let mobilePhone = this.patientFormGroup.get(PHONE_TYPE.mobilePhone).value;
      this.patientFormGroup.patchValue({ mobilePhone: { ...mobilePhone, isPrimary: false } });
    }

    if (type != PHONE_TYPE.alternatePhone1) {
      let alternatePhone1 = this.patientFormGroup.get(PHONE_TYPE.alternatePhone1).value;
      this.patientFormGroup.patchValue({ alternatePhone1: { ...alternatePhone1, isPrimary: false } });
    }

    if (type != PHONE_TYPE.alternatePhone2) {
      let alternatePhone2 = this.patientFormGroup.get(PHONE_TYPE.alternatePhone2).value;
      this.patientFormGroup.patchValue({ alternatePhone2: { ...alternatePhone2, isPrimary: false } });
    }

    this.patientFormGroup.markAsTouched();
  }


  checkElemmentEditOnNotification() {
    this.patientFormGroup.get('primaryEmail').get('emailAddress').valueChanges.pipe(
      debounceTime(500),
      map((userInput: any) => userInput)).subscribe(res => {
        if (this.patient.primaryEmail.emailAddress != res) {
          this.warningDialogOpen = true;
        } else {
          this.warningDialogOpen = false;
        }
      });

    this.patientFormGroup.get('mobilePhone').get('number').valueChanges.pipe(
      debounceTime(500),
      map((userInput: any) => userInput)).subscribe(res => {
        let mobileNumber: any = res.replace(/\D/g, "");
        if (this.patient.mobilePhone.number != mobileNumber) {
          this.warningDialogOpen = true;
        } else {
          this.warningDialogOpen = false;
        }
      })
  }

  elementChangeOnBlur(section){
    let email: any = this.patientFormGroup.get('primaryEmail').get('emailAddress').value;
    let mobile: any = this.patientFormGroup.get('mobilePhone').get('number').value;

    if(this.warningDialogOpen == true){

      let type: any = {};

      if (section == 'email') {
        type = email == '' ? 'removed' : 'change';
      } else if(section == 'mobile'){
        type = mobile == '' ? 'removed' : 'change';
      }

      this.dialog.open(WarningDialogComponent, { type: type, element: section }).afterClosed()
        .pipe(takeUntil(this._destroy$))
        .subscribe(resp => {
          this.warningDialogOpen = false;

          if (resp == 'cancel') {
            if (section == 'email') {
              this.patientFormGroup.get('primaryEmail').patchValue({
                emailAddress: this.patient.primaryEmail.emailAddress
              });
            } else if(section == 'mobile'){
              this.patientFormGroup.get('mobilePhone').patchValue({
                number: this.patient.mobilePhone.number
              })
            }

            this.saveBtnClicked = false;
          } else if (resp == 'continue') {
            if (this.saveBtnClicked == true) {
              this.saveDetails();
            }
          } else {
            this.saveBtnClicked = false;
          }
        });
    }
  }

  checkFutureMessages() {
    this.isWorking = true;
    this._patientClient.patient_GetMessages(this.patient.id, undefined, -1)
      .pipe(takeUntil(this._destroy$))
      .subscribe(resp => {
        let _messages = resp.filter(message => message.messageType != 'Notification');
        if (_messages.length > 0) {
          const dialogRef = this.matDialog.open(FutureMessagesDialogComponent, { data: { patientId: this.patient.id } })
        }
        this.isWorking = false;
      }, err => {
        this.isWorking = false;
      });
  }

  refresh(): void {
    let patient = this.patientFormGroup.value;
    if (!patient || !patient.id || !patient.preferredLocationId) return;

    this._store$.dispatch(PatientsStoreActions.LoadOneRequest({
      id: patient.id,
      locationId: patient.preferredLocationId
    }));

    this.data.flip.next(<IFlipEvent>{ payload: null, side: this.data.side });
  }

  noteDateChange(evt){
    let noteDate = moment(evt.value).format('MM/DD/YYYY');
    let note = this.patientFormGroup.value.comments;

    this.patientFormGroup.patchValue({
      comments: `${noteDate} ${note}`
    })
  }
}

import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogConfig, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

export enum ViewerType {
  Image = 'Image',
  Model = 'Model',
  IFrame = 'IFrame',
  WordDoc = 'WordDoc',
  Other = 'Other',
  Tiff = 'Tiff',
  Plaintext = 'Plaintext'
}

declare var Tiff: any;

@Component({
  selector: 'file-viewer',
  templateUrl: './file-viewer.component.html',
  styleUrls: ['./file-viewer.component.css']
})
export class FileViewerComponent implements OnInit, OnDestroy {
  @ViewChild('tiffCanvasContainer', { static: false }) tiffCanvasContainer: ElementRef;
  private readonly triggerElementRef: ElementRef;
  private readonly _msftOfficeViewer = 'https://view.officeapps.live.com/op/embed.aspx?src={url}';
  viewerType: ViewerType = ViewerType.Other;
  viewerTypeEnum = ViewerType;
  private _tiffMimeTypes: string[] = [
    'image/tiff',
    'image/tif'
  ];
  private _imageMimeTypes: string[] = [
    'image/apng',
    'image/avif',
    'image/bmp',
    'image/gif',
    'image/jpeg',
    'image/png',
    'image/svg+xml',
    'image/webp'
  ];
  private _modelMimeTypes: string[] = [
    'application/octet-stream',
    'model/stl',
    'model/obj',
    'model/mtl'
  ];
  private _iframeTypes: string[] = [
    'application/pdf'
  ];
  private _wordDocTypes: string[] = [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ];

  private _plainTextTypes: string[] = [
    'text/plain'
  ];


  private _destroy$: Subject<boolean> = new Subject<boolean>();
  thumbnailUrl: string;
  url: string;
  isThumbnail: boolean;
  tiffImage: any;
  isWorking: boolean = false;
  faExclamationTriangle = faExclamationTriangle;
  plainTextElem: any;

  constructor(
    private _dialogRef: MatDialogRef<FileViewerComponent>,
    private _snackbar: MatSnackBar,
    private http: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.thumbnailUrl = data.thumbnailUrl;
    this.url = data.url;
    this.viewerType = this.getViewerType(data.contentType);
    this.isThumbnail = data.isThumbnail;
    this.triggerElementRef = data.trigger;

    if (this.viewerType == this.viewerTypeEnum.Tiff) {
      this.loadTiffImage(this.isThumbnail ? this.thumbnailUrl : this.url);
    }

    if(this.viewerType == this.viewerTypeEnum.Plaintext){
      this.getPlainText();
    }
  }

  ngOnDestroy() {
    this._destroy$.next(true);
  }

  ngOnInit() {
    const matDialogConfig: MatDialogConfig = new MatDialogConfig();
    if (this.triggerElementRef && this.triggerElementRef.nativeElement) {
      const rect = this.triggerElementRef.nativeElement.getBoundingClientRect();
      matDialogConfig.position = { left: `${rect.left}px`, top: `${rect.bottom + 20}px` };
      this._dialogRef.updatePosition(matDialogConfig.position);
    }
  }

  loadTiffImage(url: string) {
    this.isWorking = true;
    this.http
      .get(url, {
        responseType: 'arraybuffer',
      })
      .pipe(
        take(1),
        takeUntil(this._destroy$)
      )
      .subscribe((response) => {
        try {
          const tiff = new Tiff({ buffer: response });
          let canvas = tiff.toCanvas();
          canvas.style.height = "100%";
          canvas.style.width = "100%";
          canvas.style.objectFit = "contain";
          canvas.style.overflow = "hidden";
          this.tiffCanvasContainer.nativeElement.appendChild(canvas);
        }
        catch (e) {
          this.viewerType = this.viewerTypeEnum.Image;
        }
        this.isWorking = false;
      });
  }

  close(): void {
    this._dialogRef.close();
  }

  loadImageFailed(): void {
    this.openSnackBar("Failed to load image", 'Ok');
    this.close();
  }

  openSnackBar(message: string, action: string) {
    this._snackbar.open(message, action, {
      duration: 3000,
    });
  }

  getViewerType(contentType: string): ViewerType {
    if (contentType) {
      if (this._imageMimeTypes.includes(contentType.toLowerCase())) return this.viewerTypeEnum.Image;
      if (this._modelMimeTypes.includes(contentType.toLowerCase())) return this.viewerTypeEnum.Model;
      if (this._iframeTypes.includes(contentType.toLowerCase())) return this.viewerTypeEnum.IFrame;
      if (this._wordDocTypes.includes(contentType.toLowerCase())) return this.viewerTypeEnum.WordDoc;
      if (this._tiffMimeTypes.includes(contentType.toLowerCase())) return this.viewerTypeEnum.Tiff;
      if (this._plainTextTypes.includes(contentType.toLowerCase())) return this.viewerTypeEnum.Plaintext;
    }
    return this.viewerTypeEnum.Other;
  }

  getMSFTViewerUrl(fileLocation: string) {
    return this._msftOfficeViewer.replace('{url}', encodeURIComponent(fileLocation));
  }

  getPlainText(){
    this.http.get(this.url, {responseType: 'text'}).pipe(take(1)).subscribe(resp => {
      this.plainTextElem = resp
    });
  }
}

import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { Observable, Subject, Subscription } from 'rxjs';
import { filter, map, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import {
  PatientsStoreSelectors,
  PatientStoreEntity,
  ResponsiblePartyStoreActions,
  ResponsiblePartyStoreEntity,
  ResponsiblePartyStoreSelectors,
  RootStoreState,
} from 'src/app/root-store';
import { LANGUAGES_LIST } from 'src/app/shared/enums';
import { CARD_DATA, ICardData, IFlipEvent } from 'src/app/shared/models';
import {
  AddressTypeEnum,
  EmailTypeEnum,
  GenderEnum,
  PreferredContactMethodEnum,
  RelationshipTypeEnum,
  PatientClient
} from 'src/app/shared/services/api.service';
import { fadeInOut } from '../animations';

import { PERMISSIONS } from '@shared/constants/permissions';
import { UserPermissionsService } from '@shared/user-permissions/user-permissions.service';
import { DialogComponent } from 'src/app/elements/dialog/dialog.component';
import { MessageDialog } from 'src/app/dialogs/message/message.dialog';
import { ACTIVE_PATIENT_LOCATION } from '../../../user-permissions/jwt.model';
import { AddPolicyDialogComponent } from 'src/app/dialogs/add-policy-dialog/add-policy-dialog.component';
import { faHospitalUser } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-patient-relationships-front',
  templateUrl: './patient-relationships-front.component.html',
  styleUrls: ['./patient-relationships-front.component.scss'],
  animations: [fadeInOut],
})
export class PatientRelationshipsFrontComponent implements OnInit, OnDestroy {
  @ViewChild('deleteConfirmTemplate', { static: true }) deleteConfirmTemplate: TemplateRef<any>;

  /** Value indicating if a default responsible party has been selected (usually first on list) */
  defaultSelected: boolean = false;
  responsibleParties$: Observable<ResponsiblePartyStoreEntity[]> = this._store$.select(ResponsiblePartyStoreSelectors.selectAllResponsibleParties);
  selectedResponsibleParty$: Observable<ResponsiblePartyStoreEntity> = this._store$.select(
    ResponsiblePartyStoreSelectors.getSelectedResponsibleParty
  );
  selectedResponsiblePartyLanguage$: Observable<string> = this.selectedResponsibleParty$.pipe(
    filter((responsibleParty) => !!responsibleParty),
    map((responsibleParty) => (responsibleParty.languageKey ? LANGUAGES_LIST[responsibleParty.languageKey.toLowerCase()].name : null))
  );
  isWorking$ = this._store$.select(ResponsiblePartyStoreSelectors.selectResponsiblePartysIsLoading);
  selectedPatient$: Observable<PatientStoreEntity> = this._store$.select(PatientsStoreSelectors.getSelectedPatient);
  /** Value indicating if the SSN field has been blinded; set to false on mouseover */
  responsiblePartySsnBlind: boolean = true;
  locationId: number;
  private _destroy$: Subject<boolean> = new Subject<boolean>();
  phonemask: any = ['(', /[0-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  private userPermissionSubscription: Subscription;
  dialog: DialogComponent;
  contactId:number;

  faHospitalUser = faHospitalUser;

  constructor(
    @Inject(CARD_DATA) public data: ICardData,
    private _store$: Store<RootStoreState.State>,
    private _dialog: MatDialog,
    private _userPermissionsService: UserPermissionsService,
    private _patientsClient: PatientClient,
    ) {
    this.selectedPatient$.pipe(takeUntil(this._destroy$)).subscribe((patient) => this.locationId = (patient && patient.locationId));

    this.dialog = new DialogComponent(this._dialog);
  }

  ngOnInit() {
    //select first responsible party in list if none has yet been selected
    this.responsibleParties$
      .pipe(withLatestFrom(this._store$.select(ResponsiblePartyStoreSelectors.getSelectedResponsiblePartyId)), takeUntil(this._destroy$))
      .subscribe(([result, selectedResponsiblePartyId]) => {
        if (selectedResponsiblePartyId == null && result.length > 0) this.selectRelationship(result[0]);
      });

      this.selectedResponsibleParty$.pipe(filter(respParty => !!respParty)).subscribe(resp => {
        this.contactId = resp.contactId;
      })
  }

  ngOnDestroy() {
    this._destroy$.next(true);

    if (this.userPermissionSubscription) {
      this.userPermissionSubscription.unsubscribe();
    }
  }

  /**
   * Dispatches a select action to the Responsible Party store
   * @param responsibleParty Responsible party to be set as selected
   */
  selectRelationship(responsibleParty: ResponsiblePartyStoreEntity) {
    /* console.log("selectRelationship", responsibleParty);
    console.log("phonemask: ", this.phonemask) */
    this._store$.dispatch(ResponsiblePartyStoreActions.SelectRequest({ id: responsibleParty.id }));
  }

  /**
   * Move a Responsible Party's position in the list
   * @param responsibleParty Responsible party entity whose order is to be changed
   * @param direction Direction in the list the entity is to be moved. 'up' moving index by -1 and 'down' moving index by 1
   * @param event Click event used to stop propagation of event up to outer button that would trigger a select event
   */
  move(responsibleParty: ResponsiblePartyStoreEntity, direction: 'down' | 'up', currentIndex: number, event: Event) {
    event.preventDefault();
    event.stopPropagation();
    //TODO: Needs API property for order
  }

  /**
   * Flip the card sending an empty responsible party that should set the component to ADD mode
   */
  add() {
    this._store$
      .select(PatientsStoreSelectors.getSelectedPatient)
      .pipe(
        map((patient) => patient.id),
        withLatestFrom(this.responsibleParties$.pipe(map((rps) => rps && rps.length))),
        take(1)
      )
      .subscribe(([patientId, responsiblePartiesCount]) =>
        this.data.flip.next(<IFlipEvent>{
          payload: new ResponsiblePartyStoreEntity({
            id: 0,
            patientId: patientId,
            relationshipType: RelationshipTypeEnum.Other,
            gender: GenderEnum.Unknown,
            preferredContactMethod: PreferredContactMethodEnum.Phone,
            sortOrder: responsiblePartiesCount || 0,
            languageKey: 'en',
            primaryAddress: {
              id: null,
              addressType: AddressTypeEnum.Home,
              contactId: null
            },
            primaryEmail: {
              id: null,
              emailType: EmailTypeEnum.Personal,
              contactId: null
            },
            hasLedgers: false,
            hasNotifications: false,
            isSecureAccess: false
          }),
          side: this.data.side,
        })
      );
  }

  /**
   * Flip the card sending the responsible party to the other side to be edited
   * @param responsibleParty Responsible party to edit
   */
  edit(responsibleParty?: ResponsiblePartyStoreEntity) {

    if (this.userPermissionSubscription) {
      this.userPermissionSubscription.unsubscribe();
    }

    this.userPermissionSubscription = this._userPermissionsService.hasPermission(PERMISSIONS.Location.TreatmentCards.EditRelationshipCard, ACTIVE_PATIENT_LOCATION).subscribe(hasPermission => {

      if (hasPermission) {
        if (responsibleParty) {
          this.data.flip.next(<IFlipEvent>{ payload: responsibleParty, side: this.data.side });
          return;
        }
      } else {
        let data = {
          title: 'Permission Error',
          message: "Sorry, you don't have rights to edit relationship card.  Please see the administrator."
        };

        this.dialog.open(MessageDialog, data);
      }
    })

  }

  /**
   * Opens a dialog confirming deletion of the responsible party, dispatches delete action when confirmed
   * @param responsibleParty Responsible party to remove
   */
  remove(responsibleParty: ResponsiblePartyStoreEntity) {
    this._store$.dispatch(
      ResponsiblePartyStoreActions.DeleteRequest({
        locationId: this.locationId,
        id: responsibleParty.id,
        patientId: responsibleParty.patientId,
      })
    );
  }

  responsiblePartySort(sortEvent: CdkDragDrop<ResponsiblePartyStoreEntity>) {
    this.responsibleParties$.pipe(take(1)).subscribe((responsibleParties) => {
      const currentIndex = sortEvent.currentIndex;
      const previousIndex = sortEvent.previousIndex;
      const responsiblePartyId: number = sortEvent.item.data.id;
      let updateRelationships: ResponsiblePartyStoreEntity[]; //this will hold references to the steps that we will save
      if (sortEvent.currentIndex - sortEvent.previousIndex < 0) {
        //item moved up in list
        updateRelationships = responsibleParties.filter((rp) => rp.sortOrder >= currentIndex && rp.sortOrder <= previousIndex);
        updateRelationships
          .filter((rp) => rp.id != responsiblePartyId)
          .forEach((rp) => {
            rp.sortOrder += 1;
          });
        responsibleParties.find((rp) => rp.id == responsiblePartyId).sortOrder = currentIndex;
      } else {
        //item moved down in list
        updateRelationships = responsibleParties.filter((step) => step.sortOrder >= previousIndex && step.sortOrder <= currentIndex);
        updateRelationships
          .filter((rp) => rp.id != responsiblePartyId)
          .forEach((rp) => {
            rp.sortOrder -= 1;
          });
        responsibleParties.find((rp) => rp.id == responsiblePartyId).sortOrder = currentIndex;
      }

      //Update local so changes reflect in ui and dispatch update action at the same time
      this._store$.dispatch(
        ResponsiblePartyStoreActions.UpdateSortOrderRequest({ responsibleParties: updateRelationships, locationId: this.locationId })
      );
    });
  }

  public phoneNumberDisplay(pnumber: any) {
    if (pnumber) {
      return `(${pnumber.substring(0, 3)}) ${pnumber.substring(
        3,
        6
      )}-${pnumber.substring(6, 10)}`;
    }
  }

  openPolicyDialog(){
    this.dialog.open(AddPolicyDialogComponent, {contactId: this.contactId}, true);
  }
}

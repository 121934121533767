import { Component, OnInit, ViewChild, ElementRef, Inject, OnDestroy, TemplateRef } from '@angular/core';
import { ActionsSubject, Store } from "@ngrx/store";
import { 
  PatientsStoreSelectors, 
  RootStoreState, LocationsStoreSelectors,
  ResponsiblePartyStoreSelectors, 
  ResponsiblePartyStoreEntity ,
  ResponsiblePartyStoreActions
} from "@root-store";
import { filter, debounceTime, map, mergeAll, toArray, take, catchError, takeUntil  } from "rxjs/operators";
import {
  PolicyDto,
  ContactClient,
  InsuranceCompanyDto,
  SettingsClient,
  GenderEnum
} from "@shared/services/api.service";
import { InputComponent } from 'src/app/elements/input/input.component';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import * as _ from "lodash";
import { untilDestroyed } from 'ngx-take-until-destroy';
import * as moment from 'moment-timezone';
import { AddEditInsuranceComponent } from 'src/app/dialogs/add-edit-insurance/add-edit-insurance.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ofType } from '@ngrx/effects';
import { Observable, fromEvent, Subject, defer, from } from 'rxjs';

@Component({
  selector: 'app-add-policy-dialog',
  templateUrl: './add-policy-dialog.component.html',
  styleUrls: ['./add-policy-dialog.component.scss']
})
export class AddPolicyDialogComponent implements OnInit, OnDestroy {
  @ViewChild('activeFromDate', { static: false }) input_activeFromDate: InputComponent;
  @ViewChild('groupPolicyNumber', { static: false }) input_groupPolicyNumber: InputComponent;
  @ViewChild('activeToDate', { static: false }) input_activeToDate: InputComponent;
  @ViewChild('employerName', { static: false }) input_employerName: InputComponent;
  @ViewChild('employerId', { static: false }) input_employerId: InputComponent;
  @ViewChild('individualPolicyNumber', { static: false }) input_individualPolicyNumber: InputComponent;
  @ViewChild("InsuranceInput", { static: false }) InsuranceInput: ElementRef;
  @ViewChild("warningDialog", {static: false}) warningDialog:TemplateRef<any>;

  primarySecondaryWorking:boolean = false;
  availablePatientContacts: any[] = [];
  primarySecondaryRelation:any;
  primarySecondaryPolicy:PolicyDtoVM;
  contactPolicies: any[] = [];
  carriersList: InsuranceCompanyDto[] = new Array();
  filterInsuranceArr: any[]=[];
  savePolicyHasError:boolean = false;
  insuranceSortType:any = 'asc';
  isShowAll:boolean = false;

  allowAddEdit:boolean = false;
  relationEditMode:boolean = false;
  genderArr:any[] = _.orderBy([...Object.entries(GenderEnum).map(([key, value]) => ({id: key, name: value}))], 'name', 'asc');
  selectedLocation$ = this._store$.select(LocationsStoreSelectors.getSelectedLocation);
  selectedLocation;
  _destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private _store$: Store<RootStoreState.State>,
    private contactClient:ContactClient,
    private settingsClient:SettingsClient,
    public dialogRef: MatDialogRef<AddPolicyDialogComponent>,
    public matDialog:MatDialog,
    private _snackBar: MatSnackBar, 
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _actions$: ActionsSubject,
  ) { }

  ngOnInit() {
    this.relationEditMode = false;
    this.getInsurances();
    this.resetPrimarySecondaryDialog();

    this._store$.select(ResponsiblePartyStoreSelectors.selectAllResponsibleParties).pipe(filter(relation => !!relation), untilDestroyed(this)).subscribe(relations => {
      if(this.data.disableAddEdit && this.data.contactId){
        this.availablePatientContacts = relations.filter(relation => relation.contactId == this.data.contactId);
        if(this.availablePatientContacts.length > 0){
          this.primarySecondary_Relation(this.availablePatientContacts[0]);
        }
      } else {
        this.availablePatientContacts = relations;
      }
      
      if(!this.data.disableAddEdit && this.data.contactId){
        let relation = this.availablePatientContacts.find(_relation => _relation.contactId == this.data.contactId);
        if(relation){
          this.primarySecondary_Relation(relation);
        }
      }
    });

    this.selectedLocation$.pipe(filter(location => !!location)).subscribe(location => {
      this.selectedLocation = location;
    })
  }

  ngOnDestroy() {
  }

  private getInsurances() {
    this.settingsClient.settings_GetInsuranceCompanies()
      .pipe(untilDestroyed(this))
      .subscribe(resp => {
        let _carriersList = resp.sort((a, b) =>
          a.shortName.toLowerCase() < b.shortName.toLowerCase() ? -1 : 1
        );
        this.carriersList = _carriersList.filter((c) => c.isActive);
        this.filterInsuranceArr = this.carriersList;
      });
  }

  primarySecondary_Relation(_relation){
    this.availablePatientContacts.map(relation => {
      relation.selected = relation.contactId == _relation.contactId;
    });

    this.primarySecondaryPolicy = null;
    this.contactPolicies = [];
    if(_relation.selected){
      this.primarySecondaryRelation = _relation;
      this.getPolicies();
    } else {
      this.primarySecondaryRelation = null;
    }

  }

  getPolicies(){
    this.primarySecondaryWorking = true;
    this.contactClient
      .contact_GetPolicies(this.primarySecondaryRelation.contactId)
      .pipe(untilDestroyed(this))
      .subscribe(contactPolicies => {
        this.primarySecondaryWorking = false;
        this.contactPolicies = contactPolicies;

        this.contactPolicies.map(policy => {

          /* if(policy.activeToDate){
             policy.isActive = moment(policy.activeToDate).diff(moment(), 'days') > 0 ? true : false;
          } else {
            policy.isActive = false;
          } */

          policy.isActive = policy.activeToDate ? false : true;
          
        });        

    },
    err => {
      this.primarySecondaryWorking = false;
      console.log(err)
    })
  }

  primarySecondaryAddPolicy(){
    this.primarySecondaryPolicy = new PolicyDtoVM();
    this.primarySecondaryPolicy.contactId = this.primarySecondaryRelation ? this.primarySecondaryRelation.contactId : this.data.contactId;
    this.primarySecondaryPolicy.activeFromDate = moment().toDate();
    this.primarySecondaryPolicy.addMode = true;
  }

  primarySecondaryInsurnaceChange(insuranceId){
    this.primarySecondaryPolicy.insuranceCompanyId = insuranceId;
    this.primarySecondaryPolicy.insuranceCompany = this.carriersList.find(insurance => insurance.id == insuranceId);
    this.getInuranceCompanyById(insuranceId);
  }

  savePolicy() {
    this.savePolicyHasError = false;

    this.savePolicyHasError = !this.primarySecondaryPolicy.insuranceCompanyId;
    this.savePolicyHasError = !this.input_employerId.validate() || this.savePolicyHasError;

    if (!this.savePolicyHasError) {
      this.primarySecondaryWorking = true;

      let policyModel: PolicyDto = new PolicyDto();
      policyModel.activeFromDate = this.primarySecondaryPolicy.activeFromDate;
      policyModel.activeToDate = this.primarySecondaryPolicy.activeToDate;
      policyModel.contactId = this.primarySecondaryPolicy.contactId;
      policyModel.employerId = this.primarySecondaryPolicy.employerId;
      policyModel.employerName = this.primarySecondaryPolicy.employerName;
      policyModel.groupPolicyNumber = this.primarySecondaryPolicy.groupPolicyNumber;
      policyModel.individualPolicyNumber = this.primarySecondaryPolicy.individualPolicyNumber;
      policyModel.insuranceCompanyId = this.primarySecondaryPolicy.insuranceCompanyId;
      policyModel.notes = this.primarySecondaryPolicy.notes;

      if (this.primarySecondaryPolicy.id) {
        policyModel.id = this.primarySecondaryPolicy.id;
        policyModel.eTag = this.primarySecondaryPolicy.eTag;

        this.contactClient.contact_PutPolicy(
          this.primarySecondaryRelation.contactId,
          this.primarySecondaryPolicy.id,
          policyModel,
          null,
          this.primarySecondaryPolicy.eTag).pipe(untilDestroyed(this)).subscribe(policy => {
            this.primarySecondaryWorking = false;
            this.primarySecondaryPolicy = null;
            this.getPolicies();
          },
            err => {
              this.primarySecondaryWorking = false;
            })
      } else {

        this.contactClient.contact_PostPolicy(
          this.primarySecondaryRelation.contactId,
          policyModel
        ).pipe(untilDestroyed(this)).subscribe(policy => {
          this.primarySecondaryWorking = false;
          this.primarySecondaryPolicy = null;
          this.getPolicies();
        },
          err => {
            this.primarySecondaryWorking = false;
          })
      }
    }
  }

  selectPolicy(_policy){
    this.contactPolicies.map(policy => {
      if(policy.id != _policy.id){
        policy.selected = false
      }
    })
    _policy.selected = _policy.selected == true ? false : true;
    _policy.editMode = false;
    this.getInuranceCompanyById(_policy.insuranceCompanyId, true);

    if(_policy.selected){
      this.primarySecondaryPolicy = _policy;
      //this.primarySecondaryPolicy.isActive = moment(this.primarySecondaryPolicy.activeToDate).diff(moment(), 'days') > 0 ? true : false;
      this.primarySecondaryPolicy.isActive = this.primarySecondaryPolicy.activeToDate ? false : true;
    } else {
      this.primarySecondaryPolicy = null;
    }

  }

  editPolicy(_policy){
    _policy.editMode = _policy.editMode == true ? false : true;
    this.primarySecondaryPolicy = _policy;
    this.getInuranceCompanyById(this.primarySecondaryPolicy.insuranceCompanyId);
  }

  getInuranceCompanyById(id, hasDelay?:boolean){
    let insurance = this.carriersList.find(insuranceCompnay => insuranceCompnay.id == id);
    if(hasDelay){
      setTimeout(() => {
        if(this.InsuranceInput){
          if(insurance){
            this.InsuranceInput.nativeElement.value = insurance.shortName;
          } else {
            this.InsuranceInput.nativeElement.value = "";
          }
        }
        
      }, 500);
    } else {
      if(this.InsuranceInput){
        if(insurance){
          this.InsuranceInput.nativeElement.value = insurance.shortName;
        } else {
          this.InsuranceInput.nativeElement.value = "";
        }
      }
    }
  }

  resetPrimarySecondaryDialog(){
    this.primarySecondaryPolicy = null;
    this.primarySecondaryRelation = null;
    this.availablePatientContacts.map(relation => {
      relation.selected = false;
    });
    this.contactPolicies.map(item => {
      item.selected = false;
    })
    this.contactPolicies = [];
    this.availablePatientContacts = [];
  }

  searchInsurance(evt) {
    let searchkeyword: any = evt.target.value;
    if (searchkeyword.length > 0) {
      this.filterInsuranceArr = this.carriersList.filter(option => (option.name.toLowerCase().includes(searchkeyword.toLowerCase()) || option.shortName.toLowerCase().includes(searchkeyword.toLowerCase()) || (option.payorNumber && option.payorNumber.toLowerCase().includes(searchkeyword.toLowerCase()))));
      if (this.filterInsuranceArr.length == 0) {
        this.filterInsuranceArr = this.carriersList;
      }

    } else {
      this.filterInsuranceArr = this.carriersList;
    }
  }

	sortInsurance(sortOn) {
		this.insuranceSortType = this.insuranceSortType == 'asc' ? 'desc' : 'asc';
		if (sortOn == 'shortname') {
			this.filterInsuranceArr = _.orderBy(this.filterInsuranceArr, [inusrance => inusrance.shortName.toLowerCase()], [this.insuranceSortType]);
		} else {
			this.filterInsuranceArr = _.orderBy(this.filterInsuranceArr, [inusrance => inusrance.name.toLowerCase()], [this.insuranceSortType]);
		}
	}

  closeDialog(){
    this.resetPrimarySecondaryDialog();
    this.dialogRef.close();
  }

  toggleActivePolicy(){
    if(this.primarySecondaryPolicy.activeToDate){
      this.primarySecondaryPolicy.activeToDate = null
    } else {
      this.matDialog.open(this.warningDialog);
    }
  }

  showAll(){
    this.isShowAll = this.isShowAll ? false : true;
  }

  addInsuranceModal(isEditMode, _insuranceCompany?:any){
    let insuranceCompany;
    let mode;
    
    if(isEditMode){
      if(_insuranceCompany){
        insuranceCompany = _insuranceCompany;
      } else {
        insuranceCompany = this.primarySecondaryPolicy.insuranceCompany;
      }
      
      mode = "edit";
    } else {
      insuranceCompany = new InsuranceCompanyDto();
      insuranceCompany.isActive = true;
      mode = "add";
    }

    const addEditDialogRef = this.matDialog.open(AddEditInsuranceComponent, {data: {formdata: insuranceCompany, mode: mode}, 'height': '100%', 'maxHeight': '750px', 'width': '400px'});

    addEditDialogRef.afterClosed().subscribe(result => {
      if(result){
        if(result == 'saved'){
          this.ngOnInit();
          this._snackBar.open('Saved', 'Close', {duration: 3000,});
          this.getInsurances();
        } else if(result == 'error'){
          this._snackBar.open('Failed to save' , 'Close', {duration: 3000, panelClass: ['error-snackbar']});
        }
      }
    })
  }

  onChanegTerminationDt(evnt){
    if(evnt == 'Invalid Date'){
      this.primarySecondaryPolicy.activeToDate = null
    } else {
      this.primarySecondaryPolicy.activeToDate = evnt;
    }
  }

  relationInfoError(){
    let _saveRelationInfoError:boolean = false;

    if(this.relationEditMode && this.primarySecondaryRelation.dob && this.primarySecondaryRelation.gender){
      _saveRelationInfoError = false;
    } else {
      _saveRelationInfoError = true;
    }

    return _saveRelationInfoError;
  }

  editRelation(){
    this.relationEditMode = this.relationEditMode ? false : true;
  }

  updateRelationInfo(){

    let _primarySecondaryRelation = JSON.parse(JSON.stringify(this.primarySecondaryRelation));
    _primarySecondaryRelation.dob = moment.utc(_primarySecondaryRelation.dob).toDate();

    this._store$.dispatch(ResponsiblePartyStoreActions.UpdateRequest({ locationId: this.selectedLocation.id, responsibleParty: _primarySecondaryRelation }));

    this._actions$
          .pipe(ofType(ResponsiblePartyStoreActions.UpdateSuccess), take(1), takeUntil(this._destroy$))
          .subscribe((result) => {
            this.relationEditMode = false;
            this.openSnackBar("relation updated sucessfully", "OK");
          },
          err => {
            this.openSnackBar(err.message, "OK");
          });

  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
    });
  }
}

export class PolicyDtoVM extends PolicyDto {
  selected:boolean = false;
  editMode:boolean = false;
  addMode:boolean = false;
  isActive:boolean = true;
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subject, Observable, iif, of } from 'rxjs';
import { debounceTime, takeUntil, take, share, map, filter } from 'rxjs/operators';
import { CARD_DATA, ICardData } from 'src/app/shared/models';
import { TreatmentDto, LocationClient, ProviderClient, ProviderDto3, UserBasicDto, ProviderBasicDto } from 'src/app/shared/services/api.service';
import { Store, ActionsSubject } from '@ngrx/store';
import { RootStoreState, TreatmentStoreActions, PatientTreatmentStoreActions, PatientTreatmentStoreSelectors, InternalUserStoreSelectors, ProvidersStoreSelectors} from 'src/app/root-store';
import { ofType } from '@ngrx/effects';
import { faCheckCircle, faTag, faTimesCircle, faUndo } from '@fortawesome/free-solid-svg-icons';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import * as moment from 'moment-timezone';
import { ProviderClientService } from '@shared/services/client/provider-client.service';
import { FetchAllParameter } from "@shared/services/client/client.model";

@Component({
  selector: 'app-patient-plan-back',
  templateUrl: './patient-plan-back.component.html',
  styleUrls: ['../patient-plan.module.css', './patient-plan-back.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PatientPlanBackComponent implements OnInit, OnDestroy {
  private _destroy$: Subject<boolean> = new Subject<boolean>();
  patientTreatment: TreatmentDto;
  private _locationId: number;
  private _patientId: number;

  faCalendarAlt = faCalendarAlt;
  faCheckCircle = faCheckCircle;
  faTag = faTag;
  faTimesCircle = faTimesCircle;
  faUndo = faUndo;
  selectedTab;
  isProcessing:boolean = false;
  enableExpandView:boolean = false;
  todayDate: any;
  updateLabel$: Subject<TreatmentDto> = new Subject<TreatmentDto>();
  isLoading$ = this._store$.select(PatientTreatmentStoreSelectors.selectIsLoading);

  users$: Observable<{ id: string; firstName: string; lastName: string }[]> = this._store$.select(InternalUserStoreSelectors.selectAllInternalUsers)
  .pipe(map((users) => users.filter(users => users.isActive && users.userType == 'Internal')
  .map((users) => ({ id: users.id, firstName: users.firstName, lastName: users.lastName, nickname: users.nickname }))));
  users;
  providers: ProviderDto3[] = [];
  patientTreatmentCoordinator;
  patientTreatmentProvider;

  filteredProviders: ProviderDto3[] = [];
  filteredUsers: any[]=[];

  constructor(
    @Inject(CARD_DATA) private data: ICardData,
    private _locationClient: LocationClient,
    private _store$: Store<RootStoreState.State>,
    private _actions$: ActionsSubject,
    private _cdr: ChangeDetectorRef,
    private _providerClientService: ProviderClientService,
    private _providerClient: ProviderClient,
    ) {
      this.data.backgroundColor = '#eaf6fa';
    }

  ngOnInit() {
    this.data.incoming.pipe(takeUntil(this._destroy$)).subscribe((payload: { treatment: TreatmentDto; locationId: number; patientId: number }) => {
      this.patientTreatment = Object.assign({}, payload.treatment);
      this.patientTreatmentCoordinator = this.patientTreatment.coordinator ? `${this.patientTreatment.coordinator.firstName} ${this.patientTreatment.coordinator.lastName}`: '';
      this.patientTreatmentProvider = this.patientTreatment.provider ? this.patientTreatment.provider.nickName : '';
      this._locationId = payload.locationId;
      this._patientId = payload.patientId;
      this.selectedTab = 'diagnosis';
      this._cdr.detectChanges();
    });

    this._store$.dispatch(TreatmentStoreActions.LoadOnceRequest());
    this.users$.pipe(filter(users => !!users)).subscribe(resp => {
      this.users = resp;
    })


    this._cdr.detectChanges();

    this.todayDate = moment().toDate();

    this.updateLabel$.pipe(debounceTime(1200), takeUntil(this._destroy$)).subscribe(resp => {
      this.save()
    })

    let params: FetchAllParameter[] = [
      { value: null },
      { value: null },
      { value: true },
      { value: 20 },
      { isPage: true },
    ];

    this._providerClientService.fetchAll(
      this._providerClient.provider_GetProviders,
      params,
      ProviderDto3
    ).subscribe(resp => {
      this.providers = resp.filter(provider => provider.isActive);
    });
  }

  ngOnDestroy() {
    this._destroy$.next(true);
  }

  cancel() {
    this._store$.dispatch(PatientTreatmentStoreActions.LoadTreatmentsRequest({ locationId: this._locationId, patientId: this._patientId }))
    this._actions$.pipe(ofType(PatientTreatmentStoreActions.LoadTreatmentsSuccess), take(1), takeUntil(this._destroy$)).subscribe(result => {
      this.selectedTab = null;
      this._cdr.detectChanges();
      this.data.flip.next({ payload: result, side: this.data.side });
    })
  }

  save() {

    this.isProcessing = true;

    iif(
      () => this.patientTreatment.id == 0,
      this._locationClient.location_PostTreatment(this._locationId, this._patientId, this.patientTreatment).pipe(share()),
      this._locationClient.location_PutTreatment(this._locationId, this._patientId, this.patientTreatment.id, this.patientTreatment, null, this.patientTreatment.eTag).pipe(share()),
    )
      .pipe(take(1))
      .subscribe((result) => {
        this._store$.dispatch(PatientTreatmentStoreActions.LoadTreatmentsRequest({ locationId: this._locationId, patientId: this._patientId }))
        this._actions$.pipe(ofType(PatientTreatmentStoreActions.LoadTreatmentsSuccess), take(1), takeUntil(this._destroy$)).subscribe(result => {
          this.isProcessing = false;
          let _patientTreatment = result.plans.find(plan => plan.id == this.patientTreatment.id);
          if(_patientTreatment){
            this.patientTreatment = _patientTreatment
          } else {
            this.patientTreatment = result.plans.find(plan => moment(plan.date).isSame(this.patientTreatment.date));
          }
        })
      });
  }

  selectTab(section){
    this.selectedTab = section;
    this._cdr.detectChanges();
  }

  saveLabel(){
    this.updateLabel$.next();
  }

  selectCoordinator(_value){
    let userId = _value;
    let user = this.users.find(user => user.id == userId);

    if (user) {
      let coordinator = new UserBasicDto();
      coordinator.id = user.id;
      this.patientTreatment.coordinator = coordinator;
      this.patientTreatmentCoordinator = `${user.firstName} ${user.lastName}`;
    } else {
      this.patientTreatment.coordinator = null;
      this.patientTreatmentCoordinator = null;
    }

    this.saveLabel();
  }

  selectProvider(_value){
    let providerId = _value;
    let provider = this.providers.find(provider => provider.id == providerId);

    if (provider) {
      let ptnTcProvider = new ProviderBasicDto();

      ptnTcProvider.id = provider.id;

      this.patientTreatment.provider = ptnTcProvider;

      this.patientTreatmentProvider = provider.nickName;

    } else {
      this.patientTreatment.provider = null;
      this.patientTreatmentProvider = null;
    }

    this.saveLabel();
  }

  onSearchProvider(evt){
    let searchkeyword: any = evt.target.value;
    if (searchkeyword.length > 0) {
      this.filteredProviders = this.providers.filter(option => option.nickName.toLowerCase().includes(searchkeyword.toLowerCase()))
      if (this.filteredProviders.length == 0) {
        this.filteredProviders = this.providers;
      }

    } else {
      this.filteredProviders = this.providers;
    }
  }

  onSearchUser(evt){
    let searchkeyword: any = evt.target.value;
    if (searchkeyword.length > 0) {
      this.filteredUsers = this.users.filter(option => (option.firstName.toLowerCase().includes(searchkeyword.toLowerCase()) || (option.lastName.toLowerCase().includes(searchkeyword))))
      if (this.filteredUsers.length == 0) {
        this.filteredUsers = this.users;
      }

    } else {
      this.filteredUsers = this.users;
    }
  }

  onFocusUser(){
    this.filteredUsers = this.users;
  }

  onFocusProvider(){
    this.filteredProviders = this.providers;
  }

}

import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http'
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import * as Moment from 'moment-timezone';
import { extendMoment } from 'moment-range';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, filter, map, take } from 'rxjs/operators';
import { PatientLedgerStoreSelectors, ResponsiblePartyStoreEntity, ResponsiblePartyStoreSelectors, RootStoreState, PatientLedgerStoreActions, ResponsiblePartyStoreActions, PatientsStoreSelectors, LocationsStoreSelectors } from 'src/app/root-store';
import { PatientLedgerDto, PatientClient, PatientLedgerTransactionDto, PatientLedgerNoteDto, PatientLedgerOptionsDto, RelationshipDto, AcceptedContractPlanDto, PatientContractPlanDto, PatientLedgerPaymentPlanDto, CreatePatientLedgerDto, InsuranceClaimDto, PatientDto } from './api.service';
const moment: any = extendMoment(Moment);
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class PatientService {
  selectedPatient: PatientDto;
  selectedPatientId: number;
  selectedLedgerId: number;
  selectedLedger: PatientLedgerDto;
  selectedLocationId: any;
  selectedDate: string;
  selectedPatient$: Observable<PatientDto> = this.store$.select(PatientsStoreSelectors.getSelectedPatient)
    .pipe(filter(data => data != null), distinctUntilChanged((prev, curr) => {
      return prev.id == curr.id;
    }), untilDestroyed(this));

  ledgers$: Observable<PatientLedgerDto[]> = this.store$.select(PatientLedgerStoreSelectors.allPatientLedgers);
  responsibleParties$: Observable<ResponsiblePartyStoreEntity[]> = this.store$.select(ResponsiblePartyStoreSelectors.selectAllResponsibleParties);
  selectedLedgerTransactions$: Observable<PatientLedgerTransactionDto[]> = this.store$.select(PatientLedgerStoreSelectors.selectedLedgerTransactions);
  ledgerOptions$: Observable<PatientLedgerOptionsDto> = this.store$.select(PatientLedgerStoreSelectors.ledgerOptions);
  togglePatientInfo$: Observable<boolean> = this.store$.select(PatientLedgerStoreSelectors.togglePatientInfo);
  selectedLocation$ = this.store$.select(LocationsStoreSelectors.getSelectedLocation);
  private _updateLedgerListener = new Subject<any>();

  constructor(
    private http: HttpClient,
    private patientClient: PatientClient,
    private store$: Store<RootStoreState.State>) {
    this.selectedPatient$.subscribe((patient: PatientDto) => {
      this.selectedPatient = patient;
      this.selectedPatientId = patient.id;
    });

    this.store$.select(PatientLedgerStoreSelectors.selectedPatientLedger).pipe(filter(ledger => ledger != null), untilDestroyed(this)).subscribe(ledger => {
      this.selectedLedger = ledger;
      this.selectedLedgerId = ledger.id;
    });

    this.selectedLocation$.pipe(
      filter((location) => !!location), untilDestroyed(this)).subscribe(location => {
        this.selectedLocationId = location.id;
      });
  }

  togglePatientInfo(flag: boolean) {
    this.store$.dispatch(PatientLedgerStoreActions.TogglePatientInfo({ flag: flag }));
  }

  setSelectedLedger(ledger: PatientLedgerDto) {
    this.store$.dispatch(PatientLedgerStoreActions.SetSelectedLedger({ selectedLedger: ledger }));
  }

  getLedgers(): Observable<any[]> {
    if(this.selectedPatientId){
      this.store$.dispatch(PatientLedgerStoreActions.GetPatientLedgers({ patientId: this.selectedPatientId }));
      return this.ledgers$.pipe(filter(list => list.length > 0), take(1));
    }
  }

  deleteLedger(ledgerId: number) {
    return this.patientClient.patient_DeleteLedger(this.selectedPatientId, ledgerId);
  }

  addLedger(ledger: PatientLedgerDto) {
    this.store$.dispatch(PatientLedgerStoreActions.AddLedger({ ledger: ledger }));
  }

  removeLedger(ledgerId: number) {
    this.store$.dispatch(PatientLedgerStoreActions.RemoveLedger({ ledgerId: ledgerId }));
  }

  getLedgerTransactions(ledgerId: number): Observable<PatientLedgerTransactionDto[]> {
    this.store$.dispatch(PatientLedgerStoreActions.GetLedgerTransactions({ patientId: this.selectedPatientId, ledgerId: ledgerId }));
    return this.selectedLedgerTransactions$.pipe(filter(list => list != null), take(1));
  }

  postLedger(ledger: CreatePatientLedgerDto): Observable<any> {
    return this.patientClient.patient_PostLedger(this.selectedPatientId, ledger);
  }

  putLedger(ledgerId: number, ledger: PatientLedgerDto): Observable<any> {
    return this.patientClient.patient_PutLedger(this.selectedPatientId, ledgerId, ledger);
  }

  postLedgerTransaction(ledgerId: number, ledgerTransaction: PatientLedgerTransactionDto): Observable<PatientLedgerTransactionDto> {
    return this.patientClient.patient_PostLedgerTransaction(this.selectedPatientId, ledgerId, ledgerTransaction);
  }

  postLedgerNote(ledgerId: number, note: PatientLedgerNoteDto): Observable<PatientLedgerTransactionDto> {
    return this.patientClient.patient_PostLedgerNote(this.selectedPatientId, ledgerId, note);
  }

  getLedgerOptions(): Observable<PatientLedgerOptionsDto> {
    this.store$.dispatch(PatientLedgerStoreActions.GetLedgerOptions({ patientId: this.selectedPatientId }));
    return this.ledgerOptions$.pipe(filter(res => res != null), take(1));
  }

  getRelationships() {
    this.store$.dispatch(ResponsiblePartyStoreActions.LoadRequest({ patientId: this.selectedPatientId, locationId: this.selectedLocationId }));
    return this.responsibleParties$.pipe(filter(list => list.length > 0), take(1));
  }

  getRelationship(id: number) {
    return this.patientClient.patient_GetRelationship(this.selectedPatientId, id);
  }

  getLedgerRelationShip(ledgerId:number){
    return this.patientClient.patient_GetLedgerRelationship(this.selectedPatientId, ledgerId);
  }

  postRelationship(ledgerId: number, responsibleParty: RelationshipDto) {
    localStorage.setItem('etag', responsibleParty.eTag);
    return this.patientClient.patient_PostLedgerRelationship(this.selectedPatientId, ledgerId, responsibleParty);
  }

  putRelationship(ledgerId: number, responsibleParty: RelationshipDto) {
    return this.patientClient.patient_PutLedgerRelationship(this.selectedPatientId, ledgerId, responsibleParty);
  }

  putAppointment(obj: any) {
    let locationId = obj.locationId > 0 ? obj.locationId : this.selectedLocationId;
    let url = `${environment.apiUrl}/locations/${locationId}/patients/${obj.patientId}/appointments/${obj.id}`;
    return this.http.put(url, obj);
  }

  getAcceptContractPlan(contractPlanId: number, isPaidInFull: boolean = false) {
    return this.patientClient.patient_GetAcceptContractPlan(this.selectedPatientId, contractPlanId, isPaidInFull);
  }

  postAcceptContractPlan(contractPlanId: number, contractPlan: AcceptedContractPlanDto) {
    return this.patientClient.patient_PostAcceptContractPlan(this.selectedPatientId, contractPlanId, contractPlan);
  }

  getPatientContractPlans() {
    return this.patientClient.patient_GetContractPlans(this.selectedPatientId,);
  }

  postPatientContractPlan(contractPlan: PatientContractPlanDto) {
    return this.patientClient.patient_PostContractPlan(this.selectedPatientId, contractPlan);
  }

  putPatientContractPlan(contractPlanId: number, contractPlan: PatientContractPlanDto) {
    return this.patientClient.patient_PutContractPlan(this.selectedPatientId, contractPlanId, contractPlan);
  }

  deletePatientContractPlan(contractPlanId: number) {
    return this.patientClient.patient_DeleteContractPlan(this.selectedPatientId, contractPlanId);
  }

  getLedgerPaymentPlan(ledgerId: number) {
    return this.patientClient.patient_GetLedgerPaymentPlans(this.selectedPatientId, ledgerId);
  }

  postLedgerPaymentPlan(ledgerId: number, ledger: PatientLedgerPaymentPlanDto) {
    return this.patientClient.patient_PostLedgerPaymentPlan(this.selectedPatientId, ledgerId, ledger);
  }

  getInsuranceClaims(ledgerId: number) {
    return this.patientClient.patient_GetInsuranceClaims(this.selectedPatientId, ledgerId);
  }

  getSingleInsuranceClaim(ledgerId: number, insuranceClaimId: number) {
    return this.patientClient.patient_GetInsuranceClaim(this.selectedPatientId, ledgerId, insuranceClaimId);
  }

  putInsuranceClaim(ledgerId: number, insuranceClaimId: number, insuranceClaim: InsuranceClaimDto) {
    return this.patientClient.patient_PutInsuranceClaim(this.selectedPatientId, ledgerId, insuranceClaimId, insuranceClaim);
  }

  postInsuranceClaim(ledgerId: number, insuranceClaim: InsuranceClaimDto) {
    return this.patientClient.patient_PostInsuranceClaim(this.selectedPatientId, ledgerId, insuranceClaim);
  }

  deleteInsuranceClaim(ledgerId: number, insuranceClaimId: number) {
    return this.patientClient.patient_DeleteInsuranceClaim(this.selectedPatientId, ledgerId, insuranceClaimId);
  }

  getFamilymembersAppointment(patientId){
    let url = `${environment.apiUrl}/patients/${patientId}/family/appointments`;
    return this.http.get(url);
  }
}

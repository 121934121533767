import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatBottomSheetRef, MatSnackBar, MAT_BOTTOM_SHEET_DATA } from '@angular/material';
import * as _ from 'lodash';
import { take } from 'rxjs/operators';
import { PatientClient, PatientTreatmentImageGroupDto, PhotoGroupDto } from 'src/app/shared/services/api.service';
import { PhotoGroupStoreEntity } from '../../../../root-store';

@Component({
  selector: 'move-photo-group-dialog',
  templateUrl: './move-photo-group-dialog.component.html',
  styleUrls: ['./move-photo-group-dialog.component.scss'],
})
export class MovePhotoGroupDialogComponent implements OnInit {
  movePhotoGroupTo: MovePhotoGroup;
  formControlPhotoGroupIdTo: FormControl;
  isWorking: boolean = false;
  movePhotoGroups: MovePhotoGroup[] = [];

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) private _data: MovePhotoGroupDialogData,
    private _bottomSheetRef: MatBottomSheetRef<MovePhotoGroupDialogComponent>,
    private _patientClient: PatientClient,
    private _snackBar: MatSnackBar
  ) {
    this.formControlPhotoGroupIdTo = new FormControl();
    let fromPhotoGroupId = this._data.fromPhotoGroupId;

    const patientGroup = this._data.patientPhotoGroups
      .filter((p: PatientTreatmentImageGroupDto) => {
        return p.photoGroupId != fromPhotoGroupId;
      })
      .map((x) => {
      const photoGroup: MovePhotoGroup = {
        photoGroupName: x.photoGroupName,
        photoGroupId: x.photoGroupId,
        patientTreatmentImageGroupId: x.id,
        takenWhen: x.takenWhen
      };
      return photoGroup;
      });

    const photoGroups = this._data.photoGroups
      .filter((p: PhotoGroupDto) => {
        return p.id != fromPhotoGroupId;
      })
      .map((x) => {
        const photoGroup: MovePhotoGroup = {
          photoGroupName: x.name,
          photoGroupId: x.id,
        };
        return photoGroup;
      });

    this.movePhotoGroups = patientGroup.concat(photoGroups);
  }

  ngOnInit() {}

  save() {
    this.isWorking = true;
    if (this.movePhotoGroupTo.patientTreatmentImageGroupId) {
      this.moved(this.movePhotoGroupTo.patientTreatmentImageGroupId);
    }
    else {
      let patientTreatmentImageGroup: PatientTreatmentImageGroupDto = new PatientTreatmentImageGroupDto({
        id: 0,
        patientId: this._data.patientId,
        photoGroupId: this.movePhotoGroupTo.photoGroupId,
        takenWhen: new Date()
      });

      this._patientClient.patient_PostPatientTreatmentImageGroup(
        this._data.patientId,
        patientTreatmentImageGroup
      )
        .pipe(take(1))
        .subscribe((res: PatientTreatmentImageGroupDto) => {
        this.moved(res.id);
      }, (err) => {
        console.log('error:', err);
        this.isWorking = false;
      });
    }
  }

  moved(groupId: number): void {
    this._patientClient
      .patient_MovePatientTreatmentImageGroupPhotoGroup(
        this._data.patientId,
        this._data.patientTreatmentImageGroupIdFrom,
        groupId)
      .pipe(take(1))
      .subscribe(() => {
        this.isWorking = false;
        this.done();
      }, err => this.isWorking = false);
  }

  done() {
    this._snackBar.open("Successfully moved.", "Ok", {
      duration: 3000,
    });
    this._bottomSheetRef.dismiss(true);
  }
}

export interface MovePhotoGroupDialogData {
  patientId: number;
  patientTreatmentImageGroupIdFrom: number;
  fromPhotoGroupId: number;
  patientPhotoGroups: PatientTreatmentImageGroupDto[];
  photoGroups: PhotoGroupDto[];
}

export interface MovePhotoGroup {
  photoGroupName: string;
  photoGroupId?: number;
  patientTreatmentImageGroupId?: number;
  takenWhen?: Date;
}

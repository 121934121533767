import { Component, Inject, OnInit, ElementRef, ViewChildren, QueryList, ViewChild } from '@angular/core';
import { CARD_DATA, ICardComponent, ICardData } from '../../../shared/models';
import { SettingsService } from '../../../shared/services/settings/settings.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { PatientsStoreSelectors, PatientStoreEntity, RootStoreState, PatientsStoreActions, PatientNotepadStoreSelectors, PatientNotepadStoreEntity, PatientNotepadStoreActions, AuthStoreSelectors, LocationsStoreSelectors, SettingsStoreSelectors } from 'src/app/root-store';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment-timezone';
import {filter} from 'rxjs/operators';

import {
  PatientClient,
  PatientNotepadDto,
  UserClient,
  TaskClient,
  UserTypeFilterEnum
} from 'src/app/shared/services/api.service';

import * as _ from 'lodash';
import { map, take } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material';
import html2pdf from 'html2pdf.js';
import { faBell, faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { faDroplet } from '@fortawesome/pro-regular-svg-icons';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';

@Component({
  selector: 'app-notepad',
  templateUrl: './notepad.component.html',
  styleUrls: ['./notepad.component.scss']
})
export class NotepadCardComponent implements OnInit, ICardComponent {
  @ViewChildren('textinput') textinput: QueryList<ElementRef>;
  @ViewChild('content', { static: false }) content: ElementRef;

  patient$: Observable<PatientStoreEntity> = this._store$.select(PatientsStoreSelectors.getSelectedPatient);
  isWorking$ = this._store$.select(PatientNotepadStoreSelectors.selectPatientNotepadIsLoading);

  patientNotepads$: Observable<PatientNotepadStoreEntity[]> = this._store$
    .select(PatientNotepadStoreSelectors.selectAllPatientNotepads)
    .pipe(map(patientNotepads => {
      this.initializeNotepads(patientNotepads);
      return patientNotepads;

    }));
  notepadArr: any[] = [];

  defaultColor: any = "#FFFFFF";
  addMode: boolean = false;
  today: any = "";
  notepadForm:FormGroup;
  noteSubmitted:boolean = false;
  patientDetails:any = {};
  showDeletePopup:boolean = false;
  notepadElem: any = {};
  selectedNote: any = {};
  showAddModalPopup:boolean = false;
  taskform: FormGroup;
  addsubmitted:boolean = false;
  assingedTo_all:boolean = false;
  assignTo: any[] = [];
  assignedToMe: boolean = true;
  usersArr: any[] = [];
  userDetails: any = {};
  userInfo$ = this._store$.select(AuthStoreSelectors.selectCredentials).pipe(filter(user => !!user));
  addTaskWorking:boolean = false;

  faBell = faBell;
  faDroplet = faDroplet;
  faTrashAlt = faTrashAlt;
  faPencilAlt = faPencilAlt;
  faCircleCheck = faCircleCheck;

  selectedLocation$ = this._store$.select(LocationsStoreSelectors.getSelectedLocation);
  genrtPdf: boolean = false;

  settings$ = this._store$.select(SettingsStoreSelectors.selectedSettings);

  constructor(
    @Inject(CARD_DATA) public data: ICardData,
    private settingsService: SettingsService,
    private formBuilder: FormBuilder,
    private _store$: Store<RootStoreState.State>,
    private patientClient:PatientClient,
    private sanitizer: DomSanitizer,
    private userClient: UserClient,
    private taskClient: TaskClient,
    private _snackBar: MatSnackBar
    ) {
  }

  ngOnInit() {
    this.today = moment().toDate();
    this.initialize();
    this.getPatientDetails();
  }

  initialize(){
    this.notepadForm = this.formBuilder.group({
      date: [''],
      notes: ['', Validators.required],
      colorcode: ['']
    });

    this.notepadForm.patchValue({
      date: this.today,
      colorcode: this.defaultColor

    })

    this.taskform = this.formBuilder.group({
      date: ['', Validators.required],
      msg: ['', Validators.required],
      assignedto: ['', Validators.required]
    });

    this.taskform.patchValue({
      date: this.today
    })

    this.getAllUsers();

    this.userInfo$.subscribe(res =>  {
		  this.userDetails = res;
	 })
  }

  getPatientDetails(){
    this.patient$
      .subscribe(res => {
        if (res != null) {
          this.patientDetails = res
          this._store$.dispatch(PatientNotepadStoreActions.LoadPatientNotepadRequest());
      }
    })
  }

  autoGrowTextArea(e) {
    //e.target.style.height = "0px";
    e.target.style.height = (e.target.scrollHeight)+"px";
  }

  scaleHt(elem){
    let txtareaHt: any = elem.scrollHeight;
    if(txtareaHt < 40){
      txtareaHt = 40;
    }

    return txtareaHt

  }

  editSelectedNote(elem, indx) {
    this.selectedNote = elem;
    this.selectedNote.openEdit = this.selectedNote.openEdit == true ? false : true;
    this.addMode = false;

  }

  initializeNotepads(patientNotepads: PatientNotepadDto[]): void {
    this.notepadArr = _.orderBy(patientNotepads, ['date'], ['desc']);

    this.notepadArr.map(item => {
      item.openEdit = false;
      item.noteText = this.sanitizer.bypassSecurityTrustHtml(this.applyLinebreak(item.note));
    });

    setTimeout(() => {
      this.textinput.forEach((element, index) => {
        element.nativeElement.style.height = element.nativeElement.scrollHeight + 'px';
      });
    }, 500)
  }

  selectColor(evt){
    this.notepadForm.patchValue({
      date: this.today,
      colorcode: evt

    });
  }

  editColor(evt, elem){
    this.selectedNote = elem;
    this.selectedNote.color = evt;
  }

  addNote(){
    this.selectedNote.openEdit = false;
    this.selectedNote = {};
    this.addMode = true;
  }

  saveNote() {

    if(this.noteSubmitted) return;

    if (this.addMode == false && this.selectedNote.openEdit == true) {

      let notePadModel: PatientNotepadDto = new PatientNotepadDto();
      notePadModel.id = this.selectedNote.id;
      notePadModel.patientId = this.patientDetails.id;
      notePadModel.note = this.selectedNote.note;
      notePadModel.color = this.selectedNote.color;
      notePadModel.date = this.selectedNote.date;
      this.noteSubmitted = true;

      this.patientClient.patient_PutNotepad(this.patientDetails.id, this.selectedNote.id, notePadModel, null, this.selectedNote.eTag).subscribe(
        (res) => {
          this.addMode = false;
          this.noteSubmitted = false;
          this.selectedNote.openEdit = false;
          this.notepadForm.reset();
          this.notepadForm.patchValue({
            date: this.today,
            colorcode: this.defaultColor

          });

          this._store$.dispatch(PatientNotepadStoreActions.LoadPatientNotepadRequest());
        },
        (err) => {
          this.noteSubmitted = false;
          console.log(err)
        }
      )

    } else {

      if (this.notepadForm.valid) {

        this.noteSubmitted = true;
        let noteValues: any = this.notepadForm.value;
        let notePadModel: PatientNotepadDto = new PatientNotepadDto();
        notePadModel.patientId = this.patientDetails.id;
        notePadModel.note = noteValues.notes;
        notePadModel.color = noteValues.colorcode;
        notePadModel.date = noteValues.date;

        this.patientClient.patient_PostNotepad(this.patientDetails.id, notePadModel, null).subscribe(
          (notepad) => {

            this.addMode = false;
              this.noteSubmitted = false;
              this.notepadForm.reset();
              this.notepadForm.patchValue({
                date: this.today,
                colorcode: this.defaultColor

              });

            this._store$.dispatch(PatientNotepadStoreActions.LoadPatientNotepadRequest());
          },
          (err) => {
            this.noteSubmitted = false;
            console.log(err)
          }
        )
      }
    }
  }

  deleteNote(_notepadElem){
    this.notepadElem = _notepadElem;
    this.showDeletePopup = true;
  }


  applyLinebreak(text) {
		return text.replace(/(?:\r\n|\r|\n)/g, '<br>')
  }

  noDelete(){
		this.showDeletePopup = false;
  }

  confirmDelete(){
    this.patientClient.patient_DeleteNotepad(this.patientDetails.id, this.notepadElem.id, null).subscribe(
      (res) => {
        this.addMode = false;
        this.noteSubmitted = false;
        this.showDeletePopup = false;
        this.notepadForm.reset();
        this.notepadForm.patchValue({
          date: this.today,
          colorcode: this.defaultColor
        });

        this._store$.dispatch(PatientNotepadStoreActions.LoadPatientNotepadRequest());
      },
      (err) => {
        this.noteSubmitted = false;
        console.log(err)
      }
    )
  }

  openCreateNewTask(notepadItem){
    this.showAddModalPopup = this.showAddModalPopup == true ? false : true;
    if(this.showAddModalPopup){

      let taskDate = moment(notepadItem.date).format('MM/DD/YYYY');

      this.taskform.patchValue({
        date: moment(taskDate).toDate(),
        msg: notepadItem.note,
      })


    } else {
      this.taskform.reset();
    }
  }

  closeAddModal() {
    this.showAddModalPopup = false;
    this.addsubmitted = false;
  }

  addAllAssignee(){
    this.assingedTo_all = this.assingedTo_all == true ? false : true;
  }

  addMultipleAssignTo() {
    this.assignTo.push({ "assignTo": "" });
  }

  getAllUsers() {
    this.userClient.user_GetUsers(UserTypeFilterEnum.Internal, true, 100, undefined, null).subscribe(
      (res) => {
        let allUserArr = JSON.parse(JSON.stringify(res));
        this.usersArr = allUserArr.filter(item => {
          return item.isActive == true
        })

        this.usersArr.sort((a, b) => a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase()));
      },

      (err) => {
        console.log(err)
      }
    )
  }

  saveNewTask() {
    this.addsubmitted = true;
    if (this.taskform.valid) {

      this.addTaskWorking = true;

      let taskformValue: any = this.taskform.value;

      let taskdt: any = new Date(taskformValue.date);
      let mm: any = taskdt.getMonth() + 1;
      if (mm < 10) {
        mm = "0" + mm;
      }
      let dt: any = taskdt.getDate();

      if (dt < 10) {
        dt = "0" + dt;
      }

      let yr: any = taskdt.getFullYear();
      let taskdt_formated: any = `${yr}-${mm}-${dt}`;

      let users: any[] = [];

      if(this.assingedTo_all == true){
        users = [];

        this.usersArr.map(item => {
          users.push({
            "id": item.id,
            "firstName": item.firstName,
            "middleName": item.middleName,
            "lastName": item.lastName,
            "nickname": item.nickname,
            "completedWhen": null,
            "isCompleted": false
          })
        });

        this.taskform.patchValue({
          assignedto: this.usersArr[0].id
        });

      } else {

        users = [];

        let _assignedto: any = taskformValue.assignedto;

        let userObj: any[] = this.usersArr.filter(item => {
          return item.id == _assignedto;
        })

        users = [
          {
            "id": userObj[0].id,
            "firstName": userObj[0].firstName,
            "middleName": userObj[0].middleName,
            "lastName": userObj[0].lastName,
            "nickname": userObj[0].nickname,
            "completedWhen": null,
            "isCompleted": false
          }
        ];

        if(this.assignTo.length > 0){
          this.assignTo.map(item => {
            if(item.assignTo.id){
              users.push({
                "id": item.assignTo.id,
                "firstName": item.assignTo.firstName,
                "middleName": item.assignTo.middleName,
                "lastName": item.assignTo.lastName,
                "nickname": item.assignTo.nickname,
                "completedWhen": null,
                "isCompleted": false
              })
            }
          })

        }

      }

      let assignedBy: any =  {
        "id": this.userDetails.id,
        "firstName": this.userDetails.firstName,
        "middleName": "",
        "lastName": this.userDetails.lastName,
        "nickname": this.userDetails.nickname
      };


      let taskModel: any = {
        "patientId": this.patientDetails.id,
        "message": taskformValue.msg,
        "dueDate": taskdt_formated,
        "users": users,
        "assignedBy": assignedBy
      }

      this.taskClient.task_PostTask(taskModel, null).subscribe(
        (res) => {
          this.addTaskWorking = false;
          this.showAddModalPopup = false;
          this.addsubmitted = false;
          this.openSnackBar('Task created successfully', '');

        },
        (err) => {
          this.addTaskWorking = false;
          this.showAddModalPopup = false;
          this.addsubmitted = false;
          this.openSnackBar(err, '');
          console.log(err)
        }
      )

    }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
    });
  }

  generateNotePadPDF(){
    this.genrtPdf = true;

    setTimeout(() => {
      let content = this.content.nativeElement;
      var opt = {
        margin: [0.5, 0.5, 0.5, 0.5],
        filename: 'NOTEPAD.pdf',
        image: { type: 'jpeg', quality: 1 },
        html2canvas: { scale: 2, letterRendering: true },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
        pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
      };

      html2pdf()
        .from(content)
        .set(opt)
        .toPdf()
        .get('pdf')
        .then((pdf) => {
          var totalPages = pdf.internal.getNumberOfPages();
          var pgHt = pdf.internal.pageSize.getHeight();

          for (let i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(10);
            pdf.setTextColor(150);

            const today = moment();
            let publishedDt: any = today.format('MM/DD/YYYY, hh:mm a');
            let footerText: any = `Page ${i} of ${totalPages}   ${publishedDt}`;
            pdf.text(footerText, 0.25, pgHt - 0.25);
          }

          this.genrtPdf = false;
        })
        .save();
    }, 1000);
  }

  dateMask() {
    const autoCorrectedDatePipe = createAutoCorrectedDatePipe('mm/dd/yyyy');
    return {
      mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
      keepCharPositions: true,
      pipe: autoCorrectedDatePipe,
    };
  }

}

import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http'
import { environment } from 'src/environments/environment';
import { Injectable, OnDestroy } from '@angular/core';
import * as Moment from 'moment-timezone'; // add this 1 of 4
import * as uuid from 'uuid';
import { LocationsStoreSelectors, RootStoreState } from 'src/app/root-store';
import { filter, take } from 'rxjs/operators';
import { InsuranceCompanyDto, AnalyticProcedureCodeGroupEnum,  AnalyticProcedureCodeSubGroupEnum} from '../api.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as _ from 'lodash';
import { extendMoment } from 'moment-range';
const moment: any = extendMoment(Moment);

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class SettingsService implements OnDestroy {
  storeSubcription: Subscription;

  constructor(private http: HttpClient, private store$: Store<RootStoreState.State>) { }

  getSettings() {
    let url = `${environment.apiUrl}/settings`;
    return this.http.options(url);
  }

  getProcedureGroups() {
    let url = `${environment.apiUrl}/settings/procedureGroups`;
    return this.http.get(url);
  }

  getProcedureGroup(id: number) {
    let url = `${environment.apiUrl}/settings/procedureGroups/${id}`;
    return this.http.get(url, { 'observe': 'response' });
  }

  postProcedureGroups(obj) {
    let url = `${environment.apiUrl}/settings/procedureGroups`;
    return this.http.post(url, obj);
  }

  putProcedureGroups(obj: ProcedureGroupDTO) {
    localStorage.setItem('etag', obj.etag);
    let url = `${environment.apiUrl}/settings/procedureGroups/${obj.id}`;
    return this.http.put(url, obj);
  }

  deleteProcedureGroup(obj: ProcedureGroupDTO) {
    localStorage.setItem('etag', obj.etag);
    let url = `${environment.apiUrl}/settings/procedureGroups/${obj.id}`;
    return this.http.delete(url);
  }

  getProcedure(id: number, obj: ProcedureDTO) {
    let url = `${environment.apiUrl}/settings/procedureGroups/${obj.procedureGroupId}/procedures/${id}`;
    return this.http.get(url, { 'observe': 'response' });
  }

  postProcedure(obj: ProcedureDTO) {
    let url = `${environment.apiUrl}/settings/procedureGroups/${obj.procedureGroupId}/procedures`;
    return this.http.post(url, obj);
  }

  putProcedure(obj: ProcedureDTO) {
    localStorage.setItem('etag', obj.etag);
    let url = `${environment.apiUrl}/settings/procedureGroups/${obj.procedureGroupId}/procedures/${obj.id}`;
    return this.http.put(url, obj);
  }

  deleteProcedure(obj: ProcedureDTO) {
    localStorage.setItem('etag', obj.etag);
    let url = `${environment.apiUrl}/settings/procedureGroups/${obj.procedureGroupId}/procedures/${obj.id}`;
    return this.http.delete(url);
  }

  getDoctorTime(id?: number) {
    let url = `${environment.apiUrl}/settings/doctorTime`;

    if (id > 0) {
      url += `/${id}`;
      return this.http.get(url, { 'observe': 'response' });
    }

    return this.http.get(url);
  }

  postDoctorTime(obj: DoctorTimeDTO) {
    let url = `${environment.apiUrl}/settings/doctorTime`;
    return this.http.post(url, obj);
  }

  putDoctorTime(obj: DoctorTimeDTO) {
    localStorage.setItem('etag', obj.etag);
    let url = `${environment.apiUrl}/settings/doctorTime/${obj.id}`;
    return this.http.put(url, obj);
  }

  deleteDoctorTime(obj: DoctorTimeDTO) {
    localStorage.setItem('etag', obj.etag);
    let url = `${environment.apiUrl}/settings/doctorTime/${obj.id}`;
    return this.http.delete(url);
  }

  _getLocations() {
    let url = `${environment.apiUrl}/locations`;
    return this.http.get(url);
  }

  getSchedules(id: number, date: any) {
    let url = `${environment.apiUrl}/locations/${id}/schedules?date=${date}`;
    return this.http.get(url);
  }

  getScheduleTemplates() {
    let url = `${environment.apiUrl}/settings/scheduleTemplates`;
    return this.http.get(url);
  }

  getScheduleTemplate(id?: number) {
    let url = `${environment.apiUrl}/settings/scheduleTemplates/${id}`;
    return this.http.get(url, { 'observe': 'response' });
  }

  putScheduleTemplate(obj: ScheduleTemplateDTO) {
    if (obj.id > 0) {
      localStorage.setItem('etag', obj.etag);
    }

    let url = `${environment.apiUrl}/settings/scheduleTemplates`;
    return this.http.put(url, obj);
  }

  getCalendar(id: number, startingDate?) {
    let url = `${environment.apiUrl}/settings/scheduleTemplates/locations/${id}/calendar`;
    return this.http.get(url, { params: { date: startingDate ? startingDate : new Date().toDateString() } });
  }

  getSchedule(obj: any) {
    let url = `${environment.apiUrl}/settings/scheduleTemplates/locations/${obj.id}/schedules`;
    return this.http.get(url, { params: { date: obj.date } });
  }

  postSchedule(obj: any) {
    let url = `${environment.apiUrl}/settings/scheduleTemplates/locations/${obj.locationId}/schedules`;
    return this.http.post(url, obj);
  }

  putSchedule(locationId: number, obj: CalendarDayDTO) {
    localStorage.setItem('etag', obj.etag);
    let data = { params: { date: obj.date } };
    let url = `${environment.apiUrl}/settings/scheduleTemplates/locations/${locationId}/schedules`;
    return this.http.put(url, obj, data);
  }

  deleteSchedule(obj: CalendarDayDTO) {
    localStorage.setItem('etag', obj.etag);
    let url = `${environment.apiUrl}/settings/scheduleTemplates/locations/${obj.locationId}/schedules`;
    return this.http.delete(url, { params: { date: obj.date, id: obj.locationId.toString() } });
  }

  getPatientStatusGroups() {
    let url = `${environment.apiUrl}/settings/patientStatusGroups`;
    return this.http.get(url);
  }

  ngOnDestroy() {
    if (this.storeSubcription != null) {
      this.storeSubcription.unsubscribe();
    }
  }
}

export class DTO {
  editMode: boolean = false;
  viewMode: boolean = false;
  addMode: boolean = false;
  etag: string;
  tempId: number;

  constructor(data?: any) {
    if (data != null) {
      this.etag = data.etag != null ? data.etag : data.eTag != null ? data.eTag : null;
      this.tempId = data.tempId;
    }

    let self = this;

    if (this.tempId == null) {
      setTimeout(function () {
        self.tempId = uuid.v4();
      });
    }
  }

  viewModeOn() {
    this.viewMode = true;
    this.editMode = false;
    this.addMode = false;
  }

  editModeOn() {
    this.viewMode = false;
    this.editMode = true;
    this.addMode = false;
  }

  addModeOn() {
    this.viewMode = false;
    this.editMode = false;
    this.addMode = true;
  }

  cancelMode() {
    this.viewMode = false;
    this.editMode = false;
    this.addMode = false;
  }

  convertDate(date: string, format?: string) {
    if (date == null) { return ''; }
    let mDate = date.indexOf('T') != -1 ? moment(date).utc() : moment(date);

    if (format == 'longDate') { // May 20, 2020
      return mDate.format('LL');
    } else if (format == 'shortDayName') { // Wed
      return mDate.format('llll').split(',')[0];
    } else if (format == 'fullDayName') { // Wednesday
      return mDate.format('dddd');
    } else if (format == '12hr') { // 12:00 PM
      return mDate.format('LT');
    } else if (format == '24hr') { // 14:00
      return mDate.format('HH:mm');
    } else if (format == 'default') {
      return mDate.format('L'); // 05/20/2020
    }

    return mDate.format(format); // custom moment formats
  }
}

export class ProcedureGroupDTO extends DTO {
  id: number = 0;
  name: string;
  colorCode: any;
  defaultDurationMinutes: number;
  procedures: ProcedureDTO[];
  isActive: boolean = false;
  proceduresActiveLen = 0;
  doctorTimeActiveLen = 0;
  isEventGroup: boolean = false;
  isDeleted: boolean = false;
  description: string;
  sortOrder:number = 0;

  constructor(data?: any) {
    super(data);

    if (data != null) {
      this.id = data.id;
      this.name = data.name;
      this.colorCode = data.colorCode;
      this.defaultDurationMinutes = data.defaultDurationMinutes;
      this.isActive = data.isActive;
      this.isEventGroup = data.isEventGroup;
      this.isDeleted = data.isDeleted;
      this.procedures = [];
      this.sortOrder = data.sortOrder;

      if (data.procedures != null && data.procedures.length > 0) {
        this.procedures = data.procedures.map(data => new ProcedureDTO(data));
        this.proceduresActiveLen = this.procedures.filter(p => p.isActive).length;
        this.doctorTimeActiveLen = this.procedures.filter(p => p.doctorTime != null && p.doctorTime.isActive).length;
      }
    }
  }
}

export class ProcedureDTO extends DTO {
  id: number = 0;
  code: string;
  description: string;
  procedureGroupId: number;
  doctorTimeId: number;
  analyticGroupId: any;
  doctorTime: DoctorTimeDTO;
  name: string;
  isActive: boolean;
  isDeleted: boolean;
  sortOrder: number = 0;
  isPatientRescheduleAllowed:boolean;
  isPatientCancelAllowed:boolean;
  defaultNextPeriodId?: number | null;
  changePatientStatusId?: number | null;
  isPatientSelfScheduleAllowed!: boolean;
  selfScheduleDaysBefore?: number | null;
  selfScheduleDaysAfter?: number | null;
  analyticGroup?: AnalyticProcedureCodeGroupEnum;
  analyticSubGroup?: AnalyticProcedureCodeSubGroupEnum;

  constructor(data?: any) {
    super(data);

    if (data != null) {
      this.id = data.id;
      this.code = data.code;
      this.procedureGroupId = data.procedureGroupId;
      this.doctorTimeId = data.doctorTimeId;
      this.analyticGroupId = data.analyticGroupId;
      this.doctorTime = new DoctorTimeDTO(data.doctorTime);
      this.description = data.description;
      this.isActive = data.isActive;
      this.sortOrder = data.sortOrder;
      this.isPatientRescheduleAllowed = data.isPatientRescheduleAllowed;
      this.isPatientCancelAllowed = data.isPatientCancelAllowed;
      this.defaultNextPeriodId = data.defaultNextPeriodId;
      this.changePatientStatusId = data.changePatientStatusId;
      this.isPatientSelfScheduleAllowed = data.isPatientSelfScheduleAllowed;
      this.selfScheduleDaysBefore = data.selfScheduleDaysBefore;
      this.selfScheduleDaysAfter = data.selfScheduleDaysAfter;
      this.analyticGroup = data.analyticGroup;
      this.analyticSubGroup = data.analyticSubGroup;
    }
  }
}

export class DoctorTimeDTO extends DTO {
  id: number;
  name: string;
  colorCode: any;
  durationMinutes: number;
  doctorTimeSegments: DoctorTimeSegmentDTO[];
  isActive: boolean = false;
  forEvents: boolean = false;

  constructor(data?: any) {
    super(data);

    if (data != null) {
      this.id = data.id;
      this.name = data.name;
      this.colorCode = data.colorCode;
      this.durationMinutes = data.durationMinutes;
      this.isActive = data.isActive;
      this.forEvents = data.forEvents
    }

    if (this.durationMinutes != null && (data.doctorTimeSegments == null || (data.doctorTimeSegments != null && data.doctorTimeSegments.length <= 0))) {
      let len = this.durationMinutes / 5;
      this.doctorTimeSegments = [];
      for (let i = 0; i < len; i++) {
        this.doctorTimeSegments.push(new DoctorTimeSegmentDTO({ index: i }));
      }
    } else if (this.durationMinutes != null && data.doctorTimeSegments != null && data.doctorTimeSegments.length > 0) {
      this.doctorTimeSegments = [];
      data.doctorTimeSegments = _.sortBy(data.doctorTimeSegments, ['index']);
      data.doctorTimeSegments.forEach(dt => {
        this.doctorTimeSegments.push(new DoctorTimeSegmentDTO(dt));
      });
    }
  }
}

export class DoctorTimeSegmentDTO {
  id: number = 0;
  doctorTimeId: number;
  index: number;
  timeAmount: number;
  error: boolean = false;

  constructor(data?: any) {
    if (data != null) {
      this.id = data.id;
      this.doctorTimeId = data.doctorTimeId;
      this.index = data.index;
      this.timeAmount = data.timeAmount;
      this.error = data.error;
    }
  }
}

export class ScheduleTemplateDTO extends DTO {
  id: number = 0;
  name: string;
  startDateTime: string;
  endDateTime: string;
  lunchStartTime: string;
  lunchEndTime: string;
  clockInTime: string;
  increment: number;
  colorCode: any = '#f0f4fa';
  scheduleTemplateRows: ScheduleTemplateRowDTO[] = [];
  isActive: boolean = true;
  chairsCount: number = 8;
  isClone: boolean = false;
  cloneMode: boolean = false;
  hasAppointments: boolean = false;

  constructor(data?: any, discardRows: boolean = true, formatTime: boolean = false) {
    super(data);

    if (data != null) {
      this.id = data.id;
      this.name = data.name;

      if (data.scheduleTemplateRows != null && data.scheduleTemplateRows.length > 0) {
        if (!discardRows) {
          data.scheduleTemplateRows.forEach(row => {
            let scheduleRow = new ScheduleTemplateRowDTO(row);
            this.scheduleTemplateRows.push(scheduleRow);
            this.hasAppointments = this.hasAppointments || scheduleRow.itemsCount > 0;
          });
        }

        this.chairsCount = data.scheduleTemplateRows.length;
      }

      this.startDateTime = data.startDateTime;
      this.endDateTime = data.endDateTime;
      this.lunchStartTime = data.lunchStartTime;
      this.lunchEndTime = data.lunchEndTime;
      this.isActive = data.isActive;
      this.clockInTime = data.clockInTime;

      this.setIncrement(data.increment);
      this.colorCode = data.colorCode;
      this.cloneMode = data.cloneMode;

      if (formatTime) { this.formatAllTime(); }
    }
  }

  getStartDateHour() {
    return parseInt(this.startDateTime.split(':')[0]);
  }

  setIncrement(value: any) {
    if (value == 'FiveMinutes' || value == 5) {
      this.increment = 5;
    } else if (value == 'TenMinutes' || value == 10) {
      this.increment = 10;
    } else if (value == 'FifteenMinutes' || value == 15) {
      this.increment = 15;
    }
  }

  formatTimeValue(time) {
    if (time == null) { return undefined; }
    if (time.indexOf('T') != -1) {
      let s1 = time.split('T');
      let s2 = s1[1].split(':');

      return s2[0] + ':' + s2[1];
    } else {
      let date = moment().format('l');

      return moment(date + ' ' + time)
        .format('HH:mm');
    }
  }

  formatAllTime() {
    this.startDateTime = this.formatTimeValue(this.startDateTime);
    this.lunchStartTime = this.formatTimeValue(this.lunchStartTime);
    this.lunchEndTime = this.formatTimeValue(this.lunchEndTime);
    this.endDateTime = this.formatTimeValue(this.endDateTime);
    this.clockInTime = this.formatTimeValue(this.clockInTime);
  }

  initCloneMode() {
    if (this.cloneMode) {
      this.id = 0;
      this.colorCode = '#f0f4fa';
      this.etag = undefined;
      this.isActive = true;
      this.name = '';

      this.scheduleTemplateRows.forEach(row => {
        row.id = 0;
        row.scheduleTemplateId = 0;
        row.scheduleTemplateRowItems.forEach(item => {
          item.id = 0;
          item.scheduleTemplateRowId = 0;
        });
      });
    }
  }
}

export class ScheduleTemplateRowDTO extends DTO {
  id: number = 0;
  scheduleTemplateId: number;
  title: string;
  scheduleTemplateRowItems: ScheduleTemplateRowItemDTO[] = [];
  itemsCount = 0;
  displayOrder: number = 0;
  nonScheduleableChair: boolean = false;

  constructor(data?: any) {
    super(data);

    if (data != null) {
      this.id = data.id;
      this.scheduleTemplateId = data.scheduleTemplateId;
      this.title = data.title;
      this.displayOrder = data.displayOrder;
      this.nonScheduleableChair = data.nonScheduleableChair;
      this.scheduleTemplateRowItems = [];

      if (data.scheduleTemplateRowItems != null && data.scheduleTemplateRowItems.length > 0) {
        data.scheduleTemplateRowItems.forEach(item => {
          this.scheduleTemplateRowItems.push(new ScheduleTemplateRowItemDTO(item));
          this.itemsCount++;
        });
      }
    }
  }
}

export class ScheduleTemplateRowItemDTO extends DTO {
  id: number;
  scheduleTemplateRowId: number;
  doctorTimeId: number;
  doctorTime: DoctorTimeDTO;
  isEvent: boolean;
  eventName: string;
  startTime: any;
  endTime: any;

  constructor(data?: any) {
    super(data);

    if (data != null) {
      this.id = data.id;
      this.scheduleTemplateRowId = data.scheduleTemplateRowId;
      this.doctorTimeId = data.doctorTimeId;
      this.doctorTime = new DoctorTimeDTO(data.doctorTime);
      this.isEvent = data.isEvent;
      this.eventName = data.eventName;
      this.startTime = this.cleanTime(data.startTime);
      this.endTime = data.endTime;

      if (this.doctorTime.id > 0) {
        this.calculateEndTime();
      }
    } else {
      this.doctorTime = new DoctorTimeDTO();
    }
  }

  formatTimeValue(time) {
    if (time == null) { return undefined; }
    if (time.indexOf('T') != -1) {
      let s1 = time.split('T');
      let s2 = s1[1].split(':');

      return s2[0] + ':' + s2[1];
    } else {
      let date = moment().format('l');

      return moment(date + ' ' + time)
        .format('HH:mm');
    }
  }

  cleanTime(time) {
    let ret = moment.utc(time);
    if (ret.isValid()) return ret.format('HH:mm');

    let date = moment().format('l');
    ret = moment.utc(date + ' ' + time)
    if (ret.isValid()) return ret.format('HH:mm');

    return null;
  }

  calculateEndTime() {
    let startTime = moment.utc(this.startTime);

    if (!startTime.isValid()) {
      let date = moment().format('l');
      startTime = moment(date + ' ' + this.startTime);
    }

    this.endTime = startTime
      .add(this.doctorTime.durationMinutes, 'minutes')
      .format('HH:mm');
  }
}

export class CalendarDTO extends DTO {
  locationList: LocationDTO[];

  constructor(data?: any) {
    super(data);
    this.locationList = [];

    if (data != null) {
      if (data != null) {
        setTimeout(() => {
          data.forEach(location => {
            this.locationList.push(new LocationDTO(location));
          });
        });

      }
    }
  }
}

export class LocationDTO extends DTO {
  locationId: number = 0;
  monthName: string;
  days: DayDTO[];
  year: number;

  constructor(data?: any) {
    super(data);

    if (data != null) {
      this.locationId = data.locationId;
      this.monthName = data.monthName;
      this.days = [];

      let date = new Date();
      // let today = (date.getUTCMonth() + 1) + '/' + date.getUTCDate() + '/' + date.getUTCFullYear();
      let today = moment().format('L');

      if (data.days != null) {
        data.days.forEach(day => {
          let newDay = new DayDTO(day);
          newDay.isToday = today == newDay.date;

          if (this.year == null) { this.year = newDay.year; }
          this.days.push(newDay);
        });
      }
    }
  }
}

export class DayDTO extends DTO {
  date: string;
  colorCode: "#C6C6C6";
  hasMultipleDifferentHours: false;
  day: number;
  appointmentCount: number;
  dateNum: number;
  year: number;
  selected: boolean = false;
  isToday: boolean = false;
  manualSelected: boolean = false;
  isOpen: boolean = false;

  constructor(data?: any) {
    super(data);

    if (data != null) {
      this.colorCode = data.colorCode;
      this.appointmentCount = data.appointmentCount;
      this.hasMultipleDifferentHours = data.hasMultipleDifferentHours;
      this.isOpen = data.colorCode != '#C6C6C6';
      this.setDateValues(data.date);
    }
  }

  setDateValues(date: any) {
    this.day = moment(date).utc().day();
    this.dateNum = Number(this.convertDate(date, 'D'))
    this.year = Number(this.convertDate(date, 'YYYY'))
    this.date = this.convertDate(date, 'default');
  }
}

export class CalendarDayDTO extends DTO {
  startTime: string;
  endTime: string;
  increment: number;
  lunchStartTime: string;
  lunchEndTime: string;
  clockInTime: string;
  colorCode: string = "#C6C6C6";
  date: string;
  disabled: boolean = true;
  locationId: number;
  appointmentCount: number;
  locationTimeZone: string;

  constructor(data?: any) {
    super(data);

    if (data != null) {
      this.startTime = data.startTime;
      this.endTime = data.endTime;
      this.increment = data.increment;
      this.lunchStartTime = data.lunchStartTime;
      this.lunchEndTime = data.lunchEndTime;
      this.clockInTime = data.clockInTime;
      this.appointmentCount = data.appointmentCount;
      this.locationTimeZone = data.locationTimeZone;

      this.setIncrement(data.increment);
      this.formatAllTime();
    }
  }

  setIncrement(value: any) {
    if (value == 'FiveMinutes') {
      this.increment = 5;
    } else if (value == 'TenMinutes') {
      this.increment = 10;
    } else if (value == 'FifteenMinutes') {
      this.increment = 15;
    }
  }

  formatTimeValue(time) {
    if (time == null) { return undefined; }
    return this.locationTimeZone ?
      moment(time).tz(this.locationTimeZone).format('HH:mm') :
      moment(time).format('HH:mm');
  }

  formatAllTime() {
    this.startTime = this.formatTimeValue(this.startTime);
    this.lunchStartTime = this.formatTimeValue(this.lunchStartTime);
    this.lunchEndTime = this.formatTimeValue(this.lunchEndTime);
    this.endTime = this.formatTimeValue(this.endTime);
    this.clockInTime = this.formatTimeValue(this.clockInTime);
  }
}

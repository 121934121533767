import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Store } from '@ngrx/store';
import {
  RootStoreState,
  PatientStoreEntity,
  PatientsStoreSelectors,
  PhotoTypeStoreSelectors,
  LocationsStoreSelectors,
  PhotoTypeStoreEntity,
  LocationStoreEntity,
  PhotoGroupStoreSelectors,
  PhotoGroupStoreEntity
} from '../../../../root-store';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import {
  CompareSettingDto,
  FileParameter,
  FromTypeEnum,
  PatientClient,
  PatientTreatmentImageDto,
  PatientTreatmentImageGroupDto,
  SettingsClient,
  StorageContentTypeEnum
} from '../../../../shared/services/api.service';
import { DomSanitizer } from '@angular/platform-browser';
import * as htmlToImage from 'html-to-image';

export enum CompareOrientation {
  SideBySide = "SideBySide",
  UpDown = "UpDown",
}

@Component({
  selector: 'compare',
  templateUrl: './compare.component.html',
  styleUrls: ['./compare.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CompareComponent implements OnInit {
  isWorking: boolean = false;
  selectedPhotoTypeId: number;
  selectedCompareSetting: CompareSettingDto;
  selectedCompareOrientation: CompareOrientation = CompareOrientation.SideBySide;
  compares: any[];
  private fetchAll$: any;
  patient: PatientStoreEntity;
  photoTypes: PhotoTypeStoreEntity[];
  photoGroups: PhotoGroupStoreEntity[];
  location: LocationStoreEntity;
  compareSettings: CompareSettingDto[];
  patientTreatmentImageGroups: PatientTreatmentImageGroupDto[];
  selectedPhotoGroup: PatientTreatmentImageGroupDto;
  fieldMerges: any = {};
  compareOrientation = CompareOrientation;
  compareOrientationKeys = Object.keys(CompareOrientation).map(
    (orientation) => ({ key: orientation, value: (CompareOrientation as any)[orientation] })
  );

  constructor(
    private _store$: Store<RootStoreState.State>,
    private _dialogRef: MatDialogRef<CompareComponent>,
    private _settingsClient: SettingsClient,
    private _domSanitizer: DomSanitizer,
    private _patientClient: PatientClient,
    private _snackbar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.compares = data.compares.sort(function compare(a, b) {
      return a.date - b.date;
    });
  }

  ngOnInit() {
    this.isWorking = true;
    this.initializeData();
  }

  initializeData(): void {
    this.fetchAll$ = combineLatest(
      this._store$.select(PatientsStoreSelectors.getSelectedPatient),
      this._store$.select(PhotoGroupStoreSelectors.selectAllPhotoGroups),
      this._store$.select(PhotoTypeStoreSelectors.selectAllPhotoTypes),
      this._store$.select(LocationsStoreSelectors.getSelectedLocation),
      this._settingsClient.settings_GetCompareSettings()
    )
      .subscribe(([patient, photoGroups, photoTypes, location, compareSettings]) => {
        if (
          patient
          && photoTypes && photoTypes.length > 0
          && location
          && compareSettings && compareSettings.length > 0
        ) {
          this.patient = patient;
          this.photoTypes = _.chain(photoTypes)
            .filter('isActive')
            .sortBy(['name'])
            .value();
          this.photoGroups = _.filter(photoGroups, 'isActive');
          this.location = location;
          this.compareSettings = compareSettings;

          this.fieldMerges['{PatFirst}'] = this.patient.firstName;
          this.fieldMerges['{PatLast}'] = this.patient.lastName;
          this.fieldMerges['{PatId}'] = this.patient.id;
          this.fieldMerges['{PatDOB}'] = this.patient.dob ? moment(this.patient.dob).tz(this.location.ianaTimeZone).format('MM/DD/YYYY') : '';
          this.fieldMerges['{PatAge}'] = this.patient.dob ? this.calculateAge(this.patient.dob) : '';

          this.loadGalleryImages();
        }
      });
  }

  private loadGalleryImages(): void {
    this.isWorking = true;
    this._patientClient.patient_GetPatientTreatmentImageGroups(
      this.patient.id
    ).subscribe((res: PatientTreatmentImageGroupDto[]) => {
      this.patientTreatmentImageGroups = _.sortBy(res, ['photoGroupName']);
      _.each(this.patientTreatmentImageGroups, (patientTreatmentImageGroup: any) => {
        patientTreatmentImageGroup.patientTreatmentImages = _.chain(patientTreatmentImageGroup.patientTreatmentImages)
          .uniqBy('photoTypeId')
          .keyBy('photoTypeId')
          .value();

        patientTreatmentImageGroup.dobExamDiff = this.calculateDateDiff(patientTreatmentImageGroup.takenWhen, this.patient.dob);
      });
      this.isWorking = false;
    }, (err) => {
      console.log('montage error:', err);
      this.isWorking = false;
    });
  }

  processBodyFieldMerge(message: string, photoGroupId: number) {
    if (!message) return;
    let patientTreatmentImageGroup: any = _.find(this.compares, { 'id': photoGroupId });
    if (!patientTreatmentImageGroup) return;
    let fieldMerges: any = {};

    fieldMerges['{TakenWhen}'] = patientTreatmentImageGroup.date ?
      moment(patientTreatmentImageGroup.date).tz(this.location.ianaTimeZone).format('MM/DD/YYYY') :
      '';
    fieldMerges['{AgeTaken}'] = patientTreatmentImageGroup.date && this.patient.dob ?
      this.calculateDateDiff(patientTreatmentImageGroup.date, this.patient.dob) :
      '';
    fieldMerges['{PhotoGroupName}'] = patientTreatmentImageGroup.name;

    _.each(this.fieldMerges, (val, key) => {
      message = message.replace(key, val);
    });

    _.each(fieldMerges, (val, key) => {
      message = message.replace(key, val);
    });

    return this.sanitizeInfoBox(message);
  }

  processImageHeader(photoGroupId: number, ) {
    const patientTreatmentImageGroup: any = _.find(this.compares, { 'id': photoGroupId });
    const takenWhen = patientTreatmentImageGroup.date ?
      moment(patientTreatmentImageGroup.date).tz(this.location.ianaTimeZone).format('MM/DD/YYYY') :
      '';
    const ageTaken = patientTreatmentImageGroup.date && this.patient.dob ?
      this.calculateDateDiff(patientTreatmentImageGroup.date, this.patient.dob) :
      '';
    return this.sanitizeInfoBox(`${patientTreatmentImageGroup.name} - ${takenWhen} (${ageTaken})`);
  }

  processHeaderFooterFieldMerge(message: string) {
    if (!message) return;
    _.each(this.fieldMerges, (val, key) => {
      message = message.replace(key, val);
    });

    return this.sanitizeInfoBox(message);
  }


  sanitizeInfoBox(message: string) {
    return this._domSanitizer.bypassSecurityTrustHtml(message);
  }

  private calculateDateDiff(examDate, dateOfBirth) {
    var duration = moment.duration(moment(examDate).tz(this.location.ianaTimeZone).diff(moment(dateOfBirth)));
    return `${duration.years()}y ${duration.months()}m`;
  }

  private calculateAge(dateOfBirth) {
    var duration = moment.duration(moment(new Date()).tz(this.location.ianaTimeZone).diff(moment(dateOfBirth)));
    return `${duration.years()}y ${duration.months()}m`;
  }

  closeCompare(): void {
    this._dialogRef.close();
  }

  saveCompare(): void {
    if (!this.selectedPhotoGroup || !this.selectedPhotoTypeId) return;
    this.isWorking = true;

    const nodeImage = document.getElementById('compare-data-container');
    let option: htmlToImage.Options = {
      width: 595,
      canvasWidth: 595
    };

    if (nodeImage.offsetHeight < 842)
      option.height = 842;

    htmlToImage.toBlob(nodeImage, option)
      .then((blob) => {
        const data = new Blob([blob], { type: blob.type })
        const file: FileParameter = {
          data,
          fileName: `${this.selectedPhotoGroup.photoGroupName} - ${moment().format('MM/DD/YYYY')} - Compare.png`,
        };

        let patientTreatmentImage = _.find(this.selectedPhotoGroup.patientTreatmentImages, (p: PatientTreatmentImageDto) => {
          return p.photoTypeId == this.selectedPhotoTypeId;
        });

        let uploadMontageRequest: Observable<void>;

        if (patientTreatmentImage) {
          uploadMontageRequest = this._patientClient.patient_PutPatientTreatmentImageUploadImage(
            this.patient.id,
            this.selectedPhotoGroup.id,
            patientTreatmentImage.id,
            StorageContentTypeEnum.Imaging,
            file,
            this.selectedPhotoTypeId,
            this.selectedPhotoGroup.photoGroupId
          );
        }
        else {
          uploadMontageRequest = this._patientClient.patient_PostPatientTreatmentImageUploadImage(
            this.patient.id,
            this.selectedPhotoGroup.id,
            StorageContentTypeEnum.Imaging,
            file,
            this.selectedPhotoTypeId,
            this.selectedPhotoGroup.photoGroupId
          );
        }

        uploadMontageRequest
          .subscribe(() => {
            this.isWorking = false;
            this.openSnackBar("Successfully saved", 'Ok')
            this._dialogRef.close(true);
          }, (err) => {
            console.log('upload error:', err);
            this.isWorking = false;
          });
      });
  }

  openSnackBar(message: string, action: string) {
    this._snackbar.open(message, action, {
      duration: 3000,
    });
  }
}

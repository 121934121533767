import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { DigitalSignatureComponent } from 'src/app/elements/digital-signature/digital-signature.component';

@Component({
  selector: 'app-signature-dialog',
  templateUrl: './signature-dialog.component.html',
  styleUrls: ['./signature-dialog.component.scss'],
})
export class SignatureDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('signature', { static: true }) signatureElement: DigitalSignatureComponent;
  @ViewChild('signatureTextElement', { static: true }) signatureTextElement: DigitalSignatureComponent;
  @ViewChild('signatureInitialsElement', { static: true }) signatureInitialsElement: DigitalSignatureComponent;
  @ViewChild("signatureTextInput", { static: false }) signatureTextInput: ElementRef;
  isTextSignature: boolean = false;
  signatureText: string;

  private _destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SignatureDialogData,
    private _dialogRef: MatDialogRef<SignatureDialogComponent>
  ) {
    this.isTextSignature = data.isTextSignature;
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    if (this.isTextSignature) {
      setTimeout(() => {
        this.startSignatureText();
      }, 500);
    }
  }

  ngOnDestroy() {
    this._destroy$.next(true);
  }

  done() {
    if (!this.isTextSignature) {
      this.signatureElement.generateImage();
      this._dialogRef.close(<SignatureDialogResult>{ type: this.data.type, value: this.signatureElement.value });
    }
    else {
      this.signatureTextElement.generateTextImage();
      this._dialogRef.close(<SignatureDialogResult>{
        type: this.data.type,
        value: this.signatureTextElement.value,
        initialsValue: this.signatureInitialsElement.value
      });
    }
  }

  startSignatureText() {
    fromEvent(this.signatureTextInput.nativeElement, "keyup")
      .pipe(
        debounceTime(500),
        takeUntil(this._destroy$)
    )
      .subscribe(value => {
        this.signatureInitialsElement.processTextImage(this.getInitials(this.signatureText));
        this.signatureTextElement.processTextImage(this.signatureText);
      });
  }

  getInitials(text: string): string {
    if (!/\S/.test(text)) {
      return text;
    }
    const matches = text.match(/\b(\w)/g);
    if (!matches) {
      return '';
    }
    return matches.join('').toUpperCase();
  }
}

export type SignatureType = 'initials' | 'full';

export interface SignatureDialogData {
  type?: SignatureType;
  currentValue?: string;
  isTextSignature?: boolean;
}

export interface SignatureDialogResult {
  value: string;
  type?: SignatureType;
  initialsValue?: string;
  paragraphId?: number;
}

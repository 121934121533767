import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { MessageService } from '../../../../core/message.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import emailMask from 'text-mask-addons/dist/emailMask';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import { DropdownSharedComponent } from '../dropdown-shared/dropdown-shared.component';
import { LocationService } from 'src/app/shared/services';
import { fromEvent } from 'rxjs';
import { debounceTime, map, filter, take, takeUntil } from 'rxjs/operators';
import { CommonApiService } from 'src/app/shared/services/new-appnt-service/common-api.service';
import { LANGUAGES_LIST, USSTATE_LIST } from 'src/app/shared/enums';
import {
	LocationClient,
	SettingsClient,
	ProviderClient,
	DentistClient,
	PatientClient,
	ClinicClient,
	ContactClient,
	EmployerDto,
	InsuranceCompanyDto,
	SearchClient,
	SearchPatientQueryDto,
	SearchContactQueryDto,
	PhoneTypeEnum,
	GenderEnum,
	PolicyDto
} from 'src/app/shared/services/api.service';
import { ScheduleService } from 'src/app/shared/services/schedule/schedule.service';
import { PatientsStoreActions, RootStoreState, LocationsStoreSelectors, LocationsStoreActions, AuthStoreSelectors } from 'src/app/root-store';
import { PATIENT_TITLES } from 'src/app/shared/enums/PatientTitleType';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import * as _ from 'lodash';
import { MatSnackBar } from '@angular/material';
import { untilDestroyed } from 'ngx-take-until-destroy';
import * as moment from 'moment-timezone';

@Component({
	selector: 'app-new-patient',
	templateUrl: './new-patient.component.html',
	styleUrls: ['./new-patient.component.css']
})
export class NewPatientComponent implements OnInit, OnDestroy {
	@ViewChild("Gender", { static: false }) GenderComponent: DropdownSharedComponent;
	@ViewChild("Type", { static: false }) TypeComponent: DropdownSharedComponent;
	@ViewChild("Preferredlocation", { static: false }) Preferredlocation: DropdownSharedComponent;
	@ViewChild("PreferredDoctor", { static: false }) PreferredDoctor: DropdownSharedComponent;
	@ViewChild("TreatmentStatus", { static: false }) TreatmentStatus: DropdownSharedComponent;
	@ViewChild("PreferredLang", { static: false }) PreferredLang: DropdownSharedComponent;
	@ViewChild("RefferingProfsnl", { static: false }) RefferingProfsnl: ElementRef;
	@ViewChild("RefferingPatient", { static: false }) RefferingPatient: ElementRef;
	@ViewChild("MedicalConcern", { static: false }) MedicalConcern: ElementRef;
	@ViewChild("professionalthank", { static: false }) professionalthank: ElementRef;
	@ViewChild("patientthank", { static: false }) patientthank: ElementRef;
	@ViewChild("PatientTitle", { static: false }) PatientTitle: ElementRef;
	@ViewChild("patientsDentist", { static: false }) patientsDentist: ElementRef;
	@ViewChild("InsuranceInput", { static: false }) InsuranceInput: ElementRef;
	@Output() showCreateAppnt = new EventEmitter();
	@Output() showProcedureEvent = new EventEmitter();
	@Output() passPatientInfo = new EventEmitter();
	@Output() onAbandon = new EventEmitter();
	@Output() afterSubmitPatient = new EventEmitter();
	storeSubscription: Subscription;
	emailMask = emailMask;

	@Input() patientPopUpOpen: any;
	@Input() selectedLocation: any;

	responsibleBackup: any = {};

	preferredPracArr: any[] = [{ "id": '', "option": "Preferred Location" }];

	preferredDoctorArr: any[] = [{ "id": '', "option": "Preferred Doctor" }];

	genderArr: any[] = [{ "option": "Female" }, { "option": "Male" }, { "option": "Unknown" }];
	Gender = GenderEnum;
	typeArr: any[] = [{ "option": "Mobile" }, { "option": "Home" }, { "option": "Work" }, { "option": "Other" }];

	languageArr: any[] = [{ "shortname": "en", "option": "English" }, { "shortname": "es", "option": "Spanish" }];

	private includeLanguages: string[] = ['en', 'es'];
	LANGUAGES_LIST = _.pickBy(LANGUAGES_LIST, (value, key) => {
		return this.includeLanguages.includes(key);
	  });

	familyNameArr: any[] = [
		{ "patientname": "Braiden Higa", "familyname": "Tea Family", "address": "1415 S. Jasper Ave." },
		{ "patientname": "Dougie Higa", "familyname": "Douglas Higa Family", "address": "229 W 1060 S" },
		{ "patientname": "Douglas Higa", "familyname": "Douglas Higa Family", "address": "229 W 1060 S" },
		{ "patientname": "Jessica Higa", "familyname": "Douglas Higa Family", "address": "229 W 1060 S" },
		{ "patientname": "Jason Higa", "familyname": "Higa Family", "address": "1656 Grand Ave." },
		{ "patientname": "Jason Higa Jr.", "familyname": "Higa Family", "address": "1656 Grand Ave." },
		{ "patientname": "Ryan Higa", "familyname": "", "address": "1235 NE 7th Ave" },
		{ "patientname": "Sheila Higa", "familyname": "Higa Family", "address": "1656 Grand Ave." }
	]

	filterPatientFamily: any[] = [];
	showFamilyResult: boolean = false;
	searchedFamily: any = '';
	familyName: any;
	newPratientData: any;

	phonemask: any = ['(', /[0-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
	dobmask: any = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
	ssnmask: any = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
	zipcodeMask: any = [/\d/, /\d/, /\d/, /\d/, /\d/];
	statemask: any = [/[a-zA-Z]/, /[a-zA-Z]/];

	showConfirmation: boolean = false;
	showResponsibleParty_modal: boolean = false;

	patientInfo: any = {
		"fname": "",
		"mname": "",
		"lname": "",
		"prefferedname": "",
		"birthday": "",
		"ssn": "",
		"email": "",
		"cellphone": "",
		"homephone": "",
		"street": "",
		"city": "",
		"state": "",
		"zip": "",
		"employer": ""
	}

	errorMessage = 'Are you sure you want to abandon patient information before saving?';
	abandonButton: boolean = false;
	newPratientform: FormGroup;

	showPatientResult: boolean = false;
	filterPatient: any[] = [];

	patient_data: any = [
		{ "id": 1, "firstname": "Adam", "lastname": "Carter", "dob": "07/03/1968", "status": "Start Needed", "visitdate": "01/07/2019", "location": "Stonehaven Orthodontics Lehi", "relation": [], "email": "adamcarter@gmail.com", "phone": "(801) 999-9999", "address": "234 W Main Street Salt Lake City, UT 84109", "is_patient": true },

		{ "id": 2, "firstname": "Derrick", "lastname": "Adams", "dob": "06/19/1986", "status": "Retention", "visitdate": "01/07/2019", "location": "Stonehaven Orthodontics Draper", "relation": [{ "id": 4, "firstname": "Samantha", "lastname": "Adams", "relation_type": "SP", "is_patient": false }], "email": "adams.derrick@netsuite.com", "phone": "(801) 555-8888", "address": "1656 Grand ave Draper, UT 84070", "is_patient": true },

		{ "id": 3, "firstname": "Lydia", "lastname": "Adams", "dob": "06/19/1986", "status": "Retention", "visitdate": "01/07/2019", "location": "Stonehaven Orthodontics Draper", "relation": [{ "id": 2, "firstname": "Derrick", "lastname": "Adams", "relation_type": "FA", "is_patient": true }, { "id": 4, "firstname": "Samantha", "lastname": "Adams", "relation_type": "MO", "is_patient": false }], "email": "adams.derrick@netsuite.com", "phone": "(801) 555-8888", "address": "1656 Grand ave Draper, UT 84070", "is_patient": true },

		{ "id": 4, "firstname": "Samantha", "lastname": "Adams", "dob": "06/19/1986", "status": "Retention", "visitdate": "01/07/2019", "location": "Stonehaven Orthodontics Draper", "relation": [{ "id": 2, "firstname": "Derrick", "lastname": "Adams", "relation_type": "SP", "is_patient": true }, { "id": 3, "firstname": "Lydia", "lastname": "Adams", "relation_type": "MO", "is_patient": true }], "email": "samjones@ucsd.edu", "phone": "(619) 555-8888", "address": "1656 Grand ave Draper, UT 84070", "is_patient": false },

		{ "id": 5, "firstname": "Greg", "lastname": "Adamson", "dob": "07/03/1972", "status": "Start Needed", "visitdate": "01/07/2019", "location": "Stonehaven Orthodontics Lehi", "relation": [], "email": "gregadamson@yahoo.com", "phone": "(858) 337-8888", "address": "229 W 1060 S Orem, UT 84058", "is_patient": true }
	];

	searchEnable: boolean = true;
	getSearchData: boolean = false;
	patientId: any = '';
	contactId: any = '';
	patient: any = {};
	treatmentStatusArr: any[] = [];
	showrerrefProf: boolean = false;
	showrerrefPatient: boolean = false;
	referralProfnl: any[] = [];
	referralPatient: any[] = [];
	formValueChanged: boolean = false;
	showUpdateConfirmation: boolean = false;

	defaultLang: any = "en";
	defaultTreatmentStatus: any = '';

	panelOpenState: boolean = false;
	step: any = 0;

	accordionPanel: any[] = [{ "title": "patient information" }];
	addNewResp: boolean = false;
	showNotification_modal: boolean = false;
	multipleResp: boolean = false;
	patientRespIndx: any = 0;

	insuranceCompies: any[] = [];

	newResponsiblePartyform: FormGroup;
	patientResp: any[] = [];
	contactETag: any = '';
	addedRespCounter: any = 0;
	btnState: any = "save";
	mdclAlrtArr: any[] = [];
	filterMdclAlrtArr: any[] = [];
	professionalList: any[] = [];
	selectedProfesnl: any = {};
	showProfessnlPopup: boolean = false;
	showPatientlPopup: boolean = false;
	patientAllData: any[] = [];
	selectedReferringPatient: any = {};
	employers: EmployerDto[] = [];
	selectedInsurance: any = "";
	initSearch:boolean = false;
	searchExistingPatientArr: any[] = [];
	isExistingPatient:boolean = false;
	isSearchLoading:boolean = false;
	selectedLocation$ = this._store$.select(LocationsStoreSelectors.getSelectedLocation);
	titleArr: { name: string;}[] = Object.entries(PATIENT_TITLES).map(([key, value]) => ({
		name: value.name,
	  }));

  filterTitleArr: any[];
  isLoading: boolean;
  filterInsuranceArr: any[]=[];
  insuranceSortType:any = 'asc';
  patientPolicies: PolicyDto[] = [];
  showInsuranceSection:boolean = true;
  showAddPolicyBtn:boolean = true;
  selectedPolicy:PolicyDto;
  maxEffeiveDate;

	constructor(
		private formBuilder: FormBuilder,
		private dataService: MessageService,
		private locationService: LocationService,
		private locationClient: LocationClient,
		private settingsClient: SettingsClient,
		private providerClient: ProviderClient,
		private dentistClient: DentistClient,
		private patientClient: PatientClient,
		private clinicClient: ClinicClient,
		private contactClient: ContactClient,
		private scheduleService: ScheduleService,
		private apiService: CommonApiService,
    private _searchClient: SearchClient,
    private _store$: Store<RootStoreState.State>,
    private _snackbar: MatSnackBar,
	) {
		this.selectedLocation = this.scheduleService.selectedLocation;
	}

	ngOnInit() {
		//console.log('selectedLocation: ', this.selectedLocation);
		this.maxEffeiveDate = new Date();

		this.initializePatientForm();
		this.initializeResponsiblePartyform();
		this.getLanguage();
		this.getLocations();
		this.getPatientStatusGroup();
		this.getInsurance();
		this.getMedicalAlert();
		this.getProfessionals();
		this.getEmployers();
  }

  ngOnDestroy() {
  }

	ngAfterViewInit() {
		setTimeout(() => {
			this.searchReffaral();
			this.searchMedicalAlert();
			this.titleAutoComplete();
			this.searchInsurance();
		}, 500);
	}

	initializePatientForm() {

		this.newPratientform = this.formBuilder.group({
			patientTitle: [''],
			patientFirstName: ['', Validators.required],
			patientLastName: ['', Validators.required],
			patientEmail: ['', [Validators.email]],
			patientCellPhone: ['', [Validators.pattern('^\\D?(\\d{3})\\D?\\D?(\\d{3})\\D?(\\d{4})$')]],
			gender: ['', Validators.required],
			patientdob: [''],
			patientMiddleName: [''],
			patientSsn: [''],
			patientPreferred: [''],
			patientEmp: [''],
			patientHomePh: [''],
			patientAltPhType: [''],
			patientStreetAd: [''],
			patientCity: [''],
			patientState: [''],
			patientZipCode: [''],
			preferredLocationId: [''],
			preferredProviderId: [''],
			referringProfessional: [''],
			patientSearchFamily: [''],
			patientEditFamily: [''],
			referringPatient: [''],
			medicalAlert: [''],
			notes: [''],
			patientStatusId: [''],
			referringClinicId: [''],
			languageKey: [''],
			insurance: [''],
			insuranceName: [''],
			groupNumber: [''],
			employeeId: [''],
			insuranceNotes: [''],
      dentistId: [''],
      dentistClinicId: [''],
      contactId: [''],
      contactETag: [''],
      patientEmailId: [''],
      patientEmailETag: ['']
		});


		this.newPratientform.valueChanges.subscribe(data => {
			this.formValueChanged = true;
		});

    this.selectedLocation$.pipe()
      .pipe(untilDestroyed(this))
      .subscribe(res => {
			if (res != undefined) {
				//console.log("location", res);
				this.newPratientform.patchValue({
					patientCity: res.address.city,
					patientState: res.address.state
				})
			}
		});

		this.filterTitleArr = this.titleArr;
	}

	initializeResponsiblePartyform() {
    this.newResponsiblePartyform = this.formBuilder.group({

      responsibletitle: [''],
      responsiblefname: ['', Validators.required],
      responsiblemiddlename: [''],
      responsiblelastname: ['', Validators.required],
      responsibleemail: ['', [Validators.email]],
      responsiblephone: ['', [Validators.pattern('^\\D?(\\d{3})\\D?\\D?(\\d{3})\\D?(\\d{4})$')]],
      responsibledob: [''],
      responsibleStreet: [''],
      responsibleState: [''],
      responsibleCity: [''],
      responsibleZip: [''],
      responsiblePreferred: [''],
      responsibleHomeNu: [''],
      responsibleInsurance: [''],
      responsibleInsuranceName: [''],
      responsibleInsuranceNotes: [''],
      responsibleSsn: [''],
      responsibleEmpid: [''],
      responsibleEmp: [''],
      responsibleGroup: [''],
      responsibleGender: ['', Validators.required],
      phoneType: [''],
      relationshiptoPatient: [''],
      languageKey: [''],
      individualPolicyNumber: [''],
      payorId: [''],
      effectivedate: [null]
		});
	}

	onSubmitNewPatientform(type, saveinsurance, addnewresp) {

		this.newPratientData = this.newPratientform.value;

		if (this.newPratientform.invalid) {
			this.showConfirmation = true;
			this.abandonButton = false;
			if (this.newPratientform.controls.patientFirstName.value == '') {
				this.errorMessage = 'Ooops! \n' +
					'add a first name for this patient.\n';

			}
			else if (this.newPratientform.controls.patientLastName.value == '') {
				this.errorMessage = 'Ooops! \n' +
					'add a last name for this patient.\n';

			} else if (this.newPratientform.controls.gender.value == '') {
				this.errorMessage = 'Ooops! \n' +
					'select gender for this patient.\n';

			} else if (this.newPratientform.controls.patientEmail.value != '' && this.newPratientform.controls.patientEmail.invalid) {
				this.errorMessage = 'Ooops! \n' +
					'add a valid email for this patient.\n';
			} else if (this.newPratientform.controls.patientCellPhone.value != '' && this.newPratientform.controls.patientCellPhone.invalid) {
				this.errorMessage = 'Ooops! \n' +
					'add a valid phone for this patient.\n';
			}

			return;
		} else {
			this.isLoading = true;
			if (type == 'save') {
				this.scheduled();
			} else {
				if (this.formValueChanged || !this.patientId) {
					if (this.getSearchData == true) {
						this.showUpdateConfirmation = true;
						this.isLoading = false;
					} else {
						this.submitPatientData(saveinsurance, addnewresp);
					}
				} else {
					this.getPatientData(saveinsurance, addnewresp);
				}
			}
		}

		if (saveinsurance == true && addnewresp == true) {
			this.btnState = "relation";
		} else if (saveinsurance == true && addnewresp == false) {
			this.btnState = "finished";
		} else {
			this.btnState = "save";
		}
	}


	dateMask() {
		const autoCorrectedDatePipe = createAutoCorrectedDatePipe('mm/dd/yyyy');
		return { mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/], keepCharPositions: true, pipe: autoCorrectedDatePipe };
	}

	enableSearchOption() {
		this.searchEnable = this.searchEnable == false ? true : false;
		if (this.searchEnable == false) {
			this.showPatientResult = false;
		}
	}

	selectPatient(selectedData) {
		if (selectedData.firstname == 'No patient found!') {

		} else {
			this.newPratientform.get('patientFirstName').setValue(selectedData.firstname);
			this.searchEnable = false;
		}
		this.searchEnable = false;
		this.showPatientResult = false;

	}

	findPatient(_searchStr) {
		let searchStr: any = _searchStr.toLowerCase();
		this.filterPatient = [];
		this.showPatientResult = false;

		if (searchStr.length > 1 && this.searchEnable == true) {
			this.filterPatient = this.patient_data.filter(item => {
				return item.firstname.toLowerCase().includes(searchStr);
			});

			this.showPatientResult = true;
			if (this.filterPatient.length == 0) {
				this.filterPatient.push({ "firstname": "No patient found!" })
			}

		}
	}


	findFamily(_searchStr) {
		let searchStr: any = _searchStr.toLowerCase();
		this.filterPatientFamily = [];
		this.showFamilyResult = false;

		if (searchStr.length > 1) {
			this.filterPatientFamily = this.familyNameArr.filter(item => {
				return item.patientname.toLowerCase().includes(searchStr);
			});

			if (this.filterPatientFamily.length == 0) {
				this.filterPatientFamily.push({ "patientname": "No patient found!" })
			}
			this.showFamilyResult = true;
		}
	}

	selectFamily(searchitem) {
		this.searchedFamily = searchitem.address;
		this.familyName = searchitem.familyname;

		this.newPratientform.get('patientSearchFamily').setValue(this.searchedFamily);
		this.newPratientform.get('patientEditFamily').setValue(this.familyName);
		this.showFamilyResult = false;
	}

	scheduled() {
		if (this.patientPopUpOpen == 'emptyCell') {
			this.showProcedureEvent.emit("showProcedureEvent");
		} else {
			this.showCreateAppnt.emit("showcreateappnt");
			this.passPatientInfo.emit(this.newPratientform.value);
		}
    this.isLoading = false;
	}

	cancel() {
		this.showConfirmation = true;
		this.errorMessage = 'Are you sure you want to abandon patient information before saving?';
		this.abandonButton = true;

	}

	btnAction(action) {
		if (action == 'abandon') {
			this.onAbandon.emit();
		} else {
			if (action == 'back') {
				this.showConfirmation = false;
			} else {
				if (this.patientPopUpOpen == 'emptyCell') {
					this.showProcedureEvent.emit("showProcedureEvent");
				} else {
					this.showCreateAppnt.emit("showcreateappnt");
				}

			}
		}
	}

	responsibleBackupval(evt) {
		this.responsibleBackup = evt;
	}

	getLanguage() {

		this.newPratientform.patchValue({
			"languageKey": this.defaultLang
		})
	}

	getGender(evt) {
		if (evt.option != 'Gender') {
			this.newPratientform.patchValue({
				gender: evt.option
			})
		} else {
			this.newPratientform.patchValue({
				gender: ''
			})
		}
	}


	getLocations() {
    this.locationService.getLocations()
      .pipe(untilDestroyed(this))
      .subscribe((locations: any) => {
			this.preferredPracArr = [{ "id": '', "option": "Preferred Location" }];
			let locationsdata = locations;

			locationsdata.map(item => {
				if (item.isActive) {
					this.preferredPracArr.push({ 'id': item.id, 'option': item.shortName })
				}
			});

			this.newPratientform.patchValue({
				"preferredLocationId": this.selectedLocation.id
			});

			this.getPreferredLocation(this.selectedLocation);

		});
	}

	getSelectedLang(elem) {
		if (elem.shortname != '') {
			this.newPratientform.patchValue({
				"languageKey": elem.shortname
			})
		} else {
			this.newPratientform.patchValue({
				"languageKey": 'EN'
			})
		}
	}

	getPatientStatusGroup() {
    this.settingsClient.settings_GetPatientStatusGroups(true)
      .pipe(untilDestroyed(this))
      .subscribe(
			(res) => {

				this.treatmentStatusArr = [];
				let resp: any = JSON.parse(JSON.stringify(res));

				resp.map(item => {
					if (item.name == '1000' || item.name == '2000') {
						item.patientStatuses.map(patientStatus => {
							this.treatmentStatusArr.push({ "id": patientStatus.id, "option": `${patientStatus.code} - ${patientStatus.description}` });
						});

						if (this.treatmentStatusArr.length > 0) {
							this.newPratientform.patchValue({
								patientStatusId: this.treatmentStatusArr[0].id
							})
						}
					}
				})
			},
			(err) => {
				console.log(err)
			}
		)
	}

	getPreferredLocation(elem) {

		this.newPratientform.patchValue({
			preferredLocationId: elem.id,
		});

		let doctors: any[] = [];

    this.providerClient.provider_GetProviders(null, elem.id, true, 100, undefined, null)
      .pipe(untilDestroyed(this))
      .subscribe(
			(res) => {
				this.preferredDoctorArr = [];
        doctors = JSON.parse(JSON.stringify(res));
        doctors = _.filter(doctors, 'isActive');

        _.each(doctors, d => {
          this.preferredDoctorArr.push({
            'id': d.id,
            'option': `${d.firstName} ${d.lastName}`,
            'nickname': d.nickName
          })
        });

        if (this.preferredDoctorArr.length == 1) {
          let provider = _.head(this.preferredDoctorArr);
          this.newPratientform.patchValue({
            preferredProviderId: provider.id
          });
        }
        else {
          this.newPratientform.patchValue({
            preferredProviderId: this.patient.preferredProviderId,
          });
        }
			},
			(err) => {
				console.log(err)
			}
		)

	}

	getPreferredDoctor(elem) {
		this.newPratientform.patchValue({
			preferredProviderId: elem.id,
		});
	}


	getSelectedElem(elem) {
		if (!elem.patientId) {
      this.patientPolicies = [];
      this.showInsuranceSection = true;
      this.selectedLocation$.pipe(filter(location => !!location)).subscribe(res => {
        this.selectedLocation.id = res.id
        this.newPratientform.patchValue({
          patientCity: res.address.city,
          patientState: res.address.state
        })
      });

      let patientDOB: any = '';
      let patientStreetAd: any = "";
      let patientCity: any = "";
      let patientState: any = "";
      let patientZipCode: any = "";
      let referringProfessional: any = "";
      let referringClinicId: any = "";
      let referringPatient: any = "";
      let comments: any = "";
      let patientCellPhone: any = "";
      let patientHomePh: any = "";
      let alternatePhoneType: any = "";

      if (elem.dob) {
        patientDOB = moment(elem.dob).tz('UTC').format('MM/DD/YYYY');
      }

      if (elem.mobilePhone && elem.mobilePhone.number) {
        patientCellPhone = elem.mobilePhone.number
      }

      if (elem.alternatePhone && elem.alternatePhone.number) {
        patientHomePh = elem.alternatePhone.number;
        alternatePhoneType = elem.alternatePhone.phoneType;
      }

      if (elem.primaryAddress) {
        if (elem.primaryAddress.addressLine1) {
          patientStreetAd = elem.primaryAddress.addressLine1;
        }

        if (elem.primaryAddress.city) {
          patientCity = elem.primaryAddress.city;
        }

        if (elem.primaryAddress.state) {
          patientState = elem.primaryAddress.state;
        }

        if (elem.primaryAddress.zip) {
          patientZipCode = elem.primaryAddress.zip;
        }

        if (elem.referringDentistId) {
          referringProfessional = elem.referringDentistId;
        }

        if (elem.referringClinicId) {
          referringClinicId = elem.referringClinicId;
        }

        if (elem.referringPatientId) {
          referringPatient = elem.referringPatientId;
        }

        if (elem.comments) {
          comments = elem.comments;
        }
      }

      let preferredLocationId: any = '';
      if (elem.preferredLocationId) {
        preferredLocationId = elem.preferredLocationId;

      } else {
        preferredLocationId = this.selectedLocation.id
      }

      let preferredProviderId: any = '';
      if (elem.preferredProviderId) {
        preferredProviderId = elem.preferredProviderId;
      }

      this.newPratientform.patchValue({
        patientTitle: elem.title,
        patientFirstName: elem.firstName,
        patientLastName: elem.lastName,
        patientEmail: elem.primaryEmail && elem.primaryEmail.emailAddress ? elem.primaryEmail.emailAddress : '',
        patientCellPhone: patientCellPhone,
        gender: elem.gender,
        patientdob: patientDOB,
        patientMiddleName: elem.middleName,
        patientSsn: elem.ssn,
        patientPreferred: elem.nickname,
        patientEmp: elem.employerName,
        patientHomePh: patientHomePh,
        patientStreetAd: patientStreetAd,
        patientCity: patientCity,
        patientState: patientState,
        patientZipCode: patientZipCode,
        preferredLocationId: preferredLocationId,
        preferredProviderId: preferredProviderId,
        referringProfessional: referringProfessional,
        referringClinicId: referringClinicId,
        patientSearchFamily: '',
        patientEditFamily: '',
        referringPatient: referringPatient,
        medicalAlert: elem.alerts && elem.alerts.medical ? elem.alerts.medical[0] : null,
        notes: comments,
        patientAltPhType: alternatePhoneType,
        languageKey: elem.languageKey,
        contactId: elem.contactId,
        contactETag: elem.contactETag,
        patientEmailId: elem.primaryEmail && elem.primaryEmail.emailAddress ? elem.primaryEmail.id : 0,
        patientEmailETag: elem.primaryEmail && elem.primaryEmail.emailAddress ? elem.primaryEmail.eTag : null
      });

      if (this.RefferingProfsnl) {
        if (elem.referringDentistId) {
          this.getProfessionalRefferal(elem.referringClinicId, elem.referringDentistId);
        } else {
          this.professionalthank.nativeElement.value = "";
        }
      }

      if (this.RefferingPatient) {
        if (elem.referringPatientId) {
          this.getPatientRefferal(elem.referringPatientId);
        } else {
          this.patientthank.nativeElement.value = "";
        }
      }

      this.contactId = elem.contactId;

      setTimeout(() => {
        this.formValueChanged = false;
      }, 500)
		}

		this.closeSearchPanel();
	}

	getTxStatus(elem) {
		if (elem.id != '') {
			this.TreatmentStatus.ddplaceholder = "Treatment Status: " + this.TreatmentStatus.ddplaceholder;
		}

		this.newPratientform.patchValue({ 'patientStatusId': elem.id });
	}

	getPatient() {

		this.getSearchData = true;

    this.locationClient.location_GetPatient(this.selectedLocation.id, this.patientId, null)
      .pipe(untilDestroyed(this))
      .subscribe(
			(res) => {
				this.patient = res;

				console.log("patient: ", res)

				let patientDOB: any = '';
				let email: any = '';
				let patientStreetAd: any = "";
				let patientCity: any = "";
				let patientState: any = "";
				let patientZipCode: any = "";
				let referringProfessional: any = "";
				let referringClinicId: any = "";
				let referringPatient: any = "";
				let comments: any = "";
				let patientCellPhone: any = "";
				let patientHomePh: any = "";
				let alternatePhoneType: any = "";

				if (this.patient.dob) {
					patientDOB = moment(this.patient.dob).tz('UTC').format('MM/DD/YYYY');
				}

				if (this.patient.primaryEmail && this.patient.primaryEmail.emailAddress) {
					email = this.patient.primaryEmail.emailAddress
				}

				if (this.patient.mobilePhone && this.patient.mobilePhone.number) {
					patientCellPhone = this.patient.mobilePhone.number
				}

				if (this.patient.alternatePhone && this.patient.alternatePhone.number) {
					patientHomePh = this.patient.alternatePhone.number;
					alternatePhoneType = this.patient.alternatePhone.phoneType;
				}

				if (this.patient.primaryAddress) {
					if (this.patient.primaryAddress.addressLine1) {
						patientStreetAd = this.patient.primaryAddress.addressLine1;
					}

					if (this.patient.primaryAddress.city) {
						patientCity = this.patient.primaryAddress.city;
					}

					if (this.patient.primaryAddress.state) {
						patientState = this.patient.primaryAddress.state;
					}

					if (this.patient.primaryAddress.zip) {
						patientZipCode = this.patient.primaryAddress.zip;
					}

					if (this.patient.referringDentistId) {
						referringProfessional = this.patient.referringDentistId;
					}

					if (this.patient.referringClinicId) {
						referringClinicId = this.patient.referringClinicId;
					}

					if (this.patient.referringPatientId) {
						referringPatient = this.patient.referringPatientId;
					}

					if (this.patient.comments) {
						comments = this.patient.comments;
					}

				}


				let preferredLocationId: any = '';
				if (this.patient.preferredLocationId) {
					preferredLocationId = this.patient.preferredLocationId;

				} else {
					preferredLocationId = this.selectedLocation.id
				}

				let preferredProviderId: any = '';
				if (this.patient.preferredProviderId) {
					preferredProviderId = this.patient.preferredProviderId;
				}

				this.newPratientform.patchValue({
					patientTitle: this.patient.title,
					patientFirstName: this.patient.firstName,
					patientLastName: this.patient.lastName,
					patientEmail: email,
					patientCellPhone: patientCellPhone,
					gender: this.patient.gender,
					patientdob: patientDOB,
					patientMiddleName: this.patient.middleName,
					patientSsn: this.patient.ssn,
					patientPreferred: this.patient.nickname,
					patientEmp: this.patient.employerName,
					patientHomePh: patientHomePh,
					patientStreetAd: patientStreetAd,
					patientCity: patientCity,
					patientState: patientState,
					patientZipCode: patientZipCode,
					preferredLocationId: preferredLocationId,
					preferredProviderId: preferredProviderId,
					referringProfessional: referringProfessional,
					referringClinicId: referringClinicId,
					patientSearchFamily: '',
					patientEditFamily: '',
					referringPatient: referringPatient,
          medicalAlert: this.patient.alerts && this.patient.alerts.medical ? this.patient.alerts.medical[0] : null,
					notes: comments,
					patientAltPhType: alternatePhoneType,
					languageKey: this.patient.languageKey
				});

				if (this.RefferingProfsnl) {
					if (this.patient.referringDentistId) {
						this.getProfessionalRefferal(this.patient.referringClinicId, this.patient.referringDentistId);
					} else {
						this.professionalthank.nativeElement.value = "";
					}
				}

				if (this.RefferingPatient) {
					if (this.patient.referringPatientId) {
						this.getPatientRefferal(this.patient.referringPatientId);
					} else {
						this.patientthank.nativeElement.value = "";
					}
				}

				this.contactId = this.patient.contactId;
				this.getRespPartyData();

				setTimeout(() => {
					this.formValueChanged = false;
				}, 500)
			},
			(err) => {
				console.log(err)
			}
		)
	}

	backFromRelationship(patientId) {
		this.showResponsibleParty_modal = false
		this.patientId = patientId;
		this.formValueChanged = false;
		this.getPatient();
	}

	submitPatientData(saveinsurance, addnewresp) {

		this.searchEnable = false;
		let updatePatient: boolean = false;

		if (this.patientId) {
			updatePatient = true;
		} else {
			updatePatient = false;
		}

		console.log("updatePatient", updatePatient)

		let formValue: any = this.newPratientform.value;

		if (updatePatient == true) {

			let phoneType: any = formValue.patientAltPhType;
			if (phoneType == 'Type' || phoneType == '') {
				phoneType = 'Other';
			}

			let lang: any = formValue.languageKey;
			if (lang == '') {
				lang = 'EN'
			}

			let mobilePhone: any = {};
			if (this.patient.mobilePhone) {
				mobilePhone = {
					"id": this.patient.mobilePhone.id,
          "eTag": this.patient.mobilePhone.eTag,
          "phoneType": PhoneTypeEnum.Mobile,
          "number": formValue.patientCellPhone.replace(/\D/g, "")
				}
			} else {
				mobilePhone = {
          "number": formValue.patientCellPhone.replace(/\D/g, ""),
          "phoneType": PhoneTypeEnum.Mobile,
				}
			}

			let alternatePh: any = {};
			if (this.patient.alternatePhone) {
				alternatePh = {
					"phoneType": phoneType,
					"id": this.patient.alternatePhone.id,
					"eTag": this.patient.alternatePhone.eTag,
          "number": formValue.patientHomePh.replace(/\D/g, "")
				}
			} else {
				alternatePh = {
					"phoneType": phoneType,
          "number": formValue.patientHomePh.replace(/\D/g, "")
				}
			}

			let primaryAddress: any = {};

			if (this.patient.primaryAddress) {
				primaryAddress = {
					"id": this.patient.primaryAddress.id,
					"eTag": this.patient.primaryAddress.eTag,
					"addressLine1": formValue.patientStreetAd,
					"city": formValue.patientCity,
					"state": formValue.patientState,
					"zip": formValue.patientZipCode,
				}
			} else {
				primaryAddress = {
					"addressLine1": formValue.patientStreetAd,
					"city": formValue.patientCity,
					"state": formValue.patientState,
					"zip": formValue.patientZipCode,
				}
			}

			let primaryEmail: any = {};
			if (this.patient.primaryEmail) {
				primaryEmail = {
					"id": this.patient.primaryEmail.id,
					"eTag": this.patient.primaryEmail.eTag,
					"emailAddress": formValue.patientEmail,
				}
			} else {
				primaryEmail = {
					"emailAddress": formValue.patientEmail,
				}
			}


			let patientModel: any = {
				"id": this.patient.id,
				"contactId": this.patient.contactId,
				"eTag": this.patient.eTag,
				"contactETag": this.patient.contactETag,
				"patientStatusId": formValue.patientStatusId,
				"preferredLocationId": formValue.preferredLocationId,
				"preferredProviderId": formValue.preferredProviderId,
				"firstName": formValue.patientFirstName,
				"middleName": formValue.patientMiddleName,
				"lastName": formValue.patientLastName,
				"nickname": formValue.patientPreferred,
				"gender": formValue.gender,
				"dob": formValue.patientdob,
				"employerName": formValue.patientEmp,
				"ssn": formValue.patientSsn,
				"primaryAddress": primaryAddress,
				"mobilePhone": mobilePhone,
				"alternatePhone1": alternatePh,
				"primaryEmail": primaryEmail,
				"referringDentistId": formValue.referringProfessional,
				"referringClinicId": formValue.referringClinicId,
				"referringPatientId": formValue.referringPatient,
				"comments": formValue.notes,
				"languageKey": formValue.languageKey,
				"title": formValue.patientTitle,
        "dentistId": formValue.dentistId,
        "dentistClinicId": formValue.dentistClinicId,
        "alerts": {
          "medical": [formValue.medicalAlert]
        }
			}

      this.populateInsuranceDataToComment(patientModel, formValue);

      this.locationClient.location_PutPatient(this.selectedLocation.id, this.patientId, patientModel, null, this.patient.eTag)
        .pipe(untilDestroyed(this))
        .subscribe(
				(res) => {

					this.afterSubmitPatient.emit(res.id);
					this.contactId = res.contactId;
					this.formValueChanged = false;
					this.getSearchData = true;
					this.patient = res;
					this.getPatientData(saveinsurance, addnewresp);
            		this.patientClient.patient_PostDefaultFolders(this.patientId).pipe(untilDestroyed(this)).subscribe(resp => {});

				},
				(err) => {
					console.log(err)
				}
			)

		} else {

			let phoneType: any = formValue.patientAltPhType;
			if (phoneType == 'Type' || phoneType == '') {
				phoneType = 'Other';
			}

			let lang: any = formValue.languageKey;
			if (lang == '') {
				lang = 'EN'
      }

      let mobilePhone = formValue.patientCellPhone.replace(/\D/g, "");
      let alternatePhone = formValue.patientHomePh.replace(/\D/g, "");

			let patientModel: any = {
				"patientStatusId": formValue.patientStatusId,
				"preferredLocationId": formValue.preferredLocationId,
				"preferredProviderId": formValue.preferredProviderId,
				"firstName": formValue.patientFirstName,
				"middleName": formValue.patientMiddleName,
				"lastName": formValue.patientLastName,
				"nickname": formValue.patientPreferred,
				"gender": formValue.gender,
				"dob": formValue.patientdob,
				"employerName": formValue.patientEmp,
				"ssn": formValue.patientSsn,
				"primaryAddress": {
					"addressType": "Other",
					"addressLine1": formValue.patientStreetAd,
					"city": formValue.patientCity,
					"state": formValue.patientState,
					"zip": formValue.patientZipCode,
				},
        "mobilePhone": mobilePhone ? {
          "number": mobilePhone,
          "phoneType": PhoneTypeEnum.Mobile
				} : null,
        "alternatePhone1": alternatePhone ? {
					"phoneType": phoneType,
          "number": alternatePhone
				} : null,
				"primaryEmail": {
          "emailAddress": formValue.patientEmail,
          id: formValue.patientEmailId,
          eTag: formValue.patientEmailETag
				},
				"referringDentistId": formValue.referringProfessional,
				"referringClinicId": formValue.referringClinicId,
				"referringPatientId": formValue.referringPatient,
				"comments": formValue.notes,
				"languageKey": formValue.languageKey,
				"title": formValue.patientTitle,
        "dentistId": formValue.dentistId,
        "dentistClinicId": formValue.dentistClinicId,
        "alerts": {
          "medical": [formValue.medicalAlert]
        },
        contactId: formValue.contactId,
        contactETag: formValue.contactETag
			}

      this.populateInsuranceDataToComment(patientModel, formValue);

      this.locationClient.location_PostPatient(this.selectedLocation.id, patientModel, null)
        .pipe(untilDestroyed(this))
        .subscribe(
				(res) => {
					this.patient = JSON.parse(JSON.stringify(res));
					this.patientId = res.id;
					this.contactId = res.contactId;
					this.afterSubmitPatient.emit(this.patientId);
					this.formValueChanged = false;
					this.getSearchData = true;
					this.getPatientData(saveinsurance, addnewresp);
					this._store$.dispatch(PatientsStoreActions.PatientViewed({ locationId: res.locationId, patientId: res.id, name: `${res.firstName} ${res.lastName}` }));
				},
				(err) => {
					console.log(err)
				}
			)

		}
  }

  private populateInsuranceDataToComment(model: any, formValue: any): void {
    if (!formValue || !model) return;

    if (!model.comments) {
      model.comments = '';
    }

    if (formValue.insuranceName) {
      model.comments += `Insurance: ${formValue.insuranceName}\n`;
    }

    if (formValue.groupNumber) {
      model.comments += `Group: ${formValue.groupNumber}\n`;
    }

    if (formValue.employeeId) {
      model.comments += `Employee ID: ${formValue.employeeId}\n`;
    }

    if (formValue.insuranceNotes) {
      model.comments += `Notes: ${formValue.insuranceNotes}\n`;
    }
  }

	getAlternatePhType(elem) {
		this.newPratientform.patchValue({
			patientAltPhType: elem.option
		})
	}


	searchReffaral() {
		fromEvent(this.RefferingProfsnl.nativeElement, "keyup").pipe(
			debounceTime(500),
			filter((e: KeyboardEvent) => e.keyCode !== 40),
			filter((e: KeyboardEvent) => e.keyCode !== 38),
			map((userInput: any) => userInput.target.value)).subscribe(res => {

				let searchkeyword: any = res;

				if (searchkeyword.length > 0) {
          this.dentistClient.dentist_GetDentists(searchkeyword)
            .pipe(untilDestroyed(this))
            .subscribe(
						(resp) => {
							this.referralProfnl = JSON.parse(JSON.stringify(resp));
							this.showrerrefProf = true;
						},
						(err) => {
							console.log(err)
						}
					)


				} else {
					this.showrerrefProf = false;
					this.referralProfnl = [];
				}
			})




		fromEvent(this.RefferingPatient.nativeElement, "keyup").pipe(
			debounceTime(500),
			filter((e: KeyboardEvent) => e.keyCode !== 40),
			filter((e: KeyboardEvent) => e.keyCode !== 38),
			map((userInput: any) => userInput.target.value)).subscribe(res => {

				console.log("searchkeyword: ", res)

				let searchkeyword: any = res;

				if (searchkeyword.length > 0) {

					let apiurl: any = `/patients?search=${searchkeyword}`;
					this.apiService.getApiData(apiurl).then(res => {
						this.referralPatient = JSON.parse(JSON.stringify(res));
						this.showrerrefPatient = true;
					})

				} else {
					this.referralPatient = [];
					this.showrerrefPatient = false;
				}
			})
	}

	selectReferralProfnl(elem) {
		let selectedProfsnl: any = elem.option.value;
		this.selectedProfesnl = selectedProfsnl;

		this.RefferingProfsnl.nativeElement.value = `${selectedProfsnl.firstName} ${selectedProfsnl.lastName}`;
		this.showrerrefProf = false;

		this.newPratientform.patchValue({
			referringProfessional: selectedProfsnl.id,
			referringClinicId: selectedProfsnl.clinicId
		})
	}

	selectReferralPatient(elem) {

		let selectedPatient: any = elem.option.value;
		this.selectedReferringPatient = selectedPatient;
		this.RefferingPatient.nativeElement.value = `${selectedPatient.firstName} ${selectedPatient.lastName}`;
		this.showrerrefPatient = false;

		this.newPratientform.patchValue({
			referringPatient: selectedPatient.id
		})
	}

	getProfessionalRefferal(clinicid, dentistId) {

    this.clinicClient.clinic_GetClinic(clinicid, null)
      .pipe(untilDestroyed(this))
      .subscribe(
			(dto) => {

				console.log("dto", dto)

				if (dto.dentists) {
					let dentist = dto.dentists.filter(function (dentist) {
						return dentist.id == dentistId;
					})[0];

					if (dentist) {
						this.professionalthank.nativeElement.value = `${dentist.displayName}`
					}
				}
			},
			(err) => {
				console.log(err)
			}
		)
	}

	getPatientRefferal(refpatientId) {
    this.locationClient.location_GetPatient(this.selectedLocation.id, refpatientId, null)
      .pipe(untilDestroyed(this))
      .subscribe(
			(res) => {
				let refpatient: any = JSON.parse(JSON.stringify(res));
				this.patientthank.nativeElement.value = `${refpatient.firstName} ${refpatient.lastName}`;
			},
			(err) => {
				console.log(err)
			}
		)
	}

	resetForm() {
		this.newPratientform.reset();
		this.patient = {};
		this.getSearchData = false;
		this.RefferingProfsnl.nativeElement.value = "";
		this.RefferingPatient.nativeElement.value = "";
	}

	updatePatientInfo(action) {
		this.showUpdateConfirmation = false;
		if (action == 'no') {
			this.getPatient();
			this.showConfirmation = false;
			this.formValueChanged = false;

		} else {

      this.locationClient.location_GetPatient(this.selectedLocation.id, this.patientId, null)
        .pipe(untilDestroyed(this))
        .subscribe(
				(res) => {
					this.patient = res;
					this.contactId = this.patient.contactId;

					if (this.btnState == "relation") {
						this.submitPatientData(true, true);
					} else if (this.btnState == "finished") {
						this.submitPatientData(true, false);
					} else {
						this.submitPatientData(false, false);
					}
				},
				(err) => {
					console.log(err)
				}
			)
		}
	}

  getRespPartyNumber(evt, indx) {

		if (evt.type == "addnew") {
			this.addNewResp = true;
			this.accordionPanel.push({ "title": "responsible information" });
			this.setStep(this.accordionPanel.length - 1);
		} else if (evt.type == 'noresp') {
			this.addNewResp = true;
			this.setStep(1);
		} else if (evt.type == 'cancelpanel') {
			this.accordionPanel.splice(this.accordionPanel.length - 1, 1);
      this.setStep(this.accordionPanel.length - 1);
    } else if (evt.type == 'removeSavedRelation') {
      this.accordionPanel.splice(indx, 1);
      this.setStep(this.accordionPanel.length - 1);
		} else {

			this.multipleResp = true;
			let addedResp: any = evt.numbr - 1;

			this.createPanel(addedResp)

			this.setStep(1);
		}
	}

	createPanel(addedResp) {

		if (this.addedRespCounter < addedResp-1) {
			this.patientRespIndx++;
			this.accordionPanel.push({ "title": "responsible information" });
			this.addedRespCounter++;

			setTimeout(() => {
				this.createPanel(addedResp)
			}, 1000)
		}

	}

	setStep(indx) {
		this.step = indx;
	}


  showNotification_section(evt) {
    if (evt) {
      if (
        (this.newPratientform.controls.patientEmail.value == '' ||
         this.newPratientform.controls.patientEmail.invalid) &&
        (this.newPratientform.controls.patientCellPhone.value == '' ||
         this.newPratientform.controls.patientCellPhone.invalid)
      ) {
        this.hasValidRelationshipContactInformations().then(hasValidContactInformation => {
          if (!hasValidContactInformation) {
            this._snackbar.open(
              'Please add a valid email or phone.',
              'OK'
            );
          }
          else
            this.showNotification_modal = evt;
        });
      }
      else
        this.showNotification_modal = evt;
    }
    else
		  this.showNotification_modal = evt;
  }

  hasValidRelationshipContactInformations(): Promise<boolean> {

    return new Promise(resolve => {
      this.locationClient.location_GetPatientRelationships(this.selectedLocation.id, this.patientId, undefined, undefined, null)
        .pipe(untilDestroyed(this))
        .subscribe(
        (relationships) => {
          _.each(relationships, relationship => {
            if (
              relationship.primaryEmail ||
              relationship.alternatePhone1 ||
              relationship.alternatePhone2 ||
              relationship.mobilePhone
            ) {
              resolve(true);
            }
          });
          resolve(false);
        },
        (err) => {
          console.log(err)
        }
      )
    });
  }

	backToPrevious(evt) {
		console.log("back to previous: ", evt, "accordionPanel: ", this.accordionPanel.length);
		if (evt.movetoslot == false) {

			this.getPatient();

      this.locationClient.location_GetPatientRelationships(this.selectedLocation.id, this.patientId, undefined, undefined, null)
        .pipe(untilDestroyed(this))
        .subscribe(
				(res) => {

					this.patientResp = JSON.parse(JSON.stringify(res));

					if (this.accordionPanel.length == 1) {
						this.patientRespIndx = 0;
					} else {
						if (this.patientResp[0].primaryEmail || this.patientResp[0].mobilePhone || this.patientResp[0].alternatePhone) {
							this.patientRespIndx = 0;
						} else {
							this.patientRespIndx = 1;
						}

						this.patientRespIndx = 1;
					}

					this.multipleResp = false;
					this.addNewResp = false;
					this.addedRespCounter = 0;
					if (this.accordionPanel.length > 1) {
						this.accordionPanel.splice(1, this.accordionPanel.length);
						this.accordionPanel[1] = { "title": "responsible information" };
					}

					console.log("back to previous: ", this.patientRespIndx);

          this.showNotification_section(false);

				},
				(err) => {
					console.log(err)
				}
			)

		} else {
			this.onAbandon.emit();
		}
	}

	getInsurance() {

    this.settingsClient.settings_GetInsuranceCompanies()
      .pipe(untilDestroyed(this))
      .subscribe(
		  (res) => {
			this.insuranceCompies = [];
			let insuranceCompanies = _.filter(res, 'isActive');
			_.each(insuranceCompanies, insuranceCompany => {
				let shortName = !insuranceCompany.shortName || !insuranceCompany.shortName.trim() ? insuranceCompany.name : insuranceCompany.shortName;
				this.insuranceCompies.push({
					"id": insuranceCompany.id,
					"shortName": shortName,
					"name": insuranceCompany.name,
					"addressLine1": insuranceCompany.addressLine1,
					"addressLine2": insuranceCompany.addressLine2,
					"city": insuranceCompany.city,
					"state": insuranceCompany.state,
					"zip": insuranceCompany.zip,
					"payorNumber": insuranceCompany.payorNumber
				})
			});
			this.filterInsuranceArr = this.insuranceCompies;
			this.filterInsuranceArr = _.orderBy(this.filterInsuranceArr, [inusrance => inusrance.shortName.toLowerCase()], ['asc']);
			this.insuranceSortType = 'asc';
		  },
		  (err) => {
			  console.log(err)
		  }
	  )
	}

	getPatientData(saveinsurance, addnewresp) {
		this.locationClient.location_GetPatientRelationships(this.selectedLocation.id, this.patientId, undefined, undefined, null)
			.pipe(untilDestroyed(this))
			.subscribe(
				(res) => {
					this.patientResp = JSON.parse(JSON.stringify(res));

					if (saveinsurance) {

						if (addnewresp == true) {
							this.updateRespPartyForm(this.newPratientform.value, addnewresp);

						} else {

							if (this.patientResp.length > 0) {

								if (this.patientResp[0].primaryEmail || this.patientResp[0].mobilePhone || this.patientResp[0].alternatePhone) {
									this.updateRespPartyForm(this.newPratientform.value, addnewresp);
								} else {
									if (this.patientResp.length == 1) {
										this.showConfirmation = true;
										this.errorMessage = 'Ooops! add a valid email or \nphone number for this patient.';
										this.isLoading = false;
									} else {
										this.updateRespPartyForm(this.newPratientform.value, addnewresp);
									}

								}

							} else {

								if (this.patient.primaryEmail || this.patient.mobilePhone || this.patient.alternatePhone) {
									this.updateRespPartyForm(this.newPratientform.value, addnewresp);
								} else {
									this.showConfirmation = true;
									this.errorMessage = 'Ooops! add a valid email or \nphone number for this patient.';
									this.isLoading = false;
								}
							}
						}

					} else {

						if (this.patientResp.length > 0) {
							this.getSingleRelationship();
						}
						else
							this.isLoading = false;
					}

				},
				(err) => {
					console.log(err)
				}
			)
	}


	getSingleRelationship() {

		let ptnRespIndx: any = this.patientResp.findIndex(item => item.relationshipType == 'Patient');

		console.log("getSingleResponsibleParty: ", this.patientResp, ptnRespIndx)

		if (ptnRespIndx == -1) {
			ptnRespIndx = 0;
		}

		let _patientResp: any = this.patientResp[ptnRespIndx];

		let contactDOB: any;
		if (_patientResp.dob != undefined || _patientResp.dob != null) {
			contactDOB = moment(_patientResp.dob).tz('UTC').format('MM/DD/YYYY');
		} else {
			contactDOB = '';
		}

		let addr: any = "";
		let city: any = "";
		let state: any = "";
		let zip: any = "";

		if (_patientResp.primaryAddress) {
			addr = _patientResp.primaryAddress.addressLine1;
			city = _patientResp.primaryAddress.city;
			state = _patientResp.primaryAddress.state;
			zip = _patientResp.primaryAddress.zip;
		}

		let insuranceNotes: any = '';
		if (_patientResp.insuranceNotes) {
			insuranceNotes = _patientResp.insuranceNotes;
		}

		let email: any = '';
		if (_patientResp.primaryEmail && _patientResp.primaryEmail.emailAddress) {
			email = _patientResp.primaryEmail.emailAddress;
		}

		let mobilePhone: any = '';
		if (_patientResp.mobilePhone && _patientResp.mobilePhone) {
			mobilePhone = _patientResp.mobilePhone.number
		}

		let alternatePhone: any = '';
		if (_patientResp.alternatePhone && _patientResp.alternatePhone.number) {
			alternatePhone = _patientResp.alternatePhone.number;
		}

		this.newResponsiblePartyform.patchValue({
      		responsibletitle: _patientResp.title,
			responsiblefname: _patientResp.firstName,
			responsiblemiddlename: _patientResp.middleName,
			responsiblelastname: _patientResp.lastName,
			responsibleemail: email,
			responsiblephone: mobilePhone,
			responsibledob: contactDOB,
			responsibleStreet: addr,
			responsibleState: state,
			responsibleCity: city,
			responsibleZip: zip,
			responsiblePreferred: _patientResp.nickname,
			responsibleHomeNu: alternatePhone,
			responsibleInsurance: _patientResp.insuranceCompanyId,
			responsibleInsuranceNotes: insuranceNotes,
			responsibleSsn: _patientResp.ssn,
			responsibleEmpid: _patientResp.employerId,
			responsibleEmp: _patientResp.employerName,
			responsibleGroup: _patientResp.groupPolicyNumber,
			responsibleGender: _patientResp.gender,
			phoneType: _patientResp.phoneType,
			relationshiptoPatient: _patientResp.relationshipType,
			languageKey: _patientResp.languageKey,
			individualPolicyNumber: _patientResp.individualPolicyNumber
		})
    	this.isLoading = false;
	}

	updateRespPartyForm(respData, addnewresp) {

		this.newResponsiblePartyform.patchValue({
      		relationshiptoPatient: 'Patient',
      		responsibletitle: respData.patientTitle,
			responsiblefname: respData.patientFirstName,
			responsiblemiddlename: respData.patientMiddleName,
			responsiblelastname: respData.patientLastName,
			responsibleemail: respData.patientEmail,
			responsiblephone: respData.patientCellPhone,
			responsibledob: respData.patientdob,
			responsibleStreet: respData.patientStreetAd,
			responsibleState: respData.patientState,
			responsibleCity: respData.patientCity,
			responsibleZip: respData.patientZipCode,
			responsiblePreferred: respData.patientPreferred,
			responsibleHomeNu: respData.patientHomePh,
			responsibleGender: respData.gender,
			phoneType: respData.patientAltPhType,
			responsibleSsn: respData.patientSsn,
			/* responsibleEmp: respData.patientEmp, */
			languageKey: respData.languageKey
		});

    this.contactClient.contact_GetContact(this.contactId, null)
      .pipe(untilDestroyed(this))
      .subscribe(
			(res) => {
				let _resp: any = JSON.parse(JSON.stringify(res));
				console.log("contact_GetContact: ", _resp)
				this.contactETag = _resp.eTag;
				this.contactId = _resp.id;

				this.submitRespParty(addnewresp);
			},

			(err) => {
				console.log(err)
			}
		)

	}

	submitRespParty(addnewresp) {
    this.respPartySubmit(addnewresp);
	}

	respPartySubmit(addnewresp){
		let formValue: any = this.newResponsiblePartyform.value;

		let phoneType: any = formValue.phoneType;
		if (phoneType == 'Type' || phoneType == '') {
			phoneType = 'Other';
		}

		if (this.patientResp.length > 0) {

			let ptnRespIndx: any = this.patientResp.findIndex(item => item.relationshipType == 'Patient');

			if (ptnRespIndx == -1) {
				ptnRespIndx = 0;
			}

			let primaryAddress: any = {};
			if (this.patientResp[ptnRespIndx].primaryAddress) {
				primaryAddress = {
					"id": this.patientResp[ptnRespIndx].primaryAddress.id,
					"eTag": this.patientResp[ptnRespIndx].primaryAddress.eTag,
					"addressLine1": formValue.responsibleStreet,
					"city": formValue.responsibleCity,
					"state": formValue.responsibleState,
					"zip": formValue.responsibleZip,
				}
			} else {
				primaryAddress = {
					"addressLine1": formValue.responsibleStreet,
					"city": formValue.responsibleCity,
					"state": formValue.responsibleState,
					"zip": formValue.responsibleZip,
				}
			}

			let mobilePhone;
			if (this.patientResp[ptnRespIndx].mobilePhone) {
				mobilePhone = {
					"id": this.patientResp[ptnRespIndx].mobilePhone.id,
					"eTag": this.patientResp[ptnRespIndx].mobilePhone.eTag,
					"number": formValue.responsiblephone,
					"phoneType": PhoneTypeEnum.Mobile
				}
			}

			let alternatePhone;
			if (this.patientResp[ptnRespIndx].alternatePhone) {
				alternatePhone = {
					"id": this.patientResp[ptnRespIndx].alternatePhone.id,
					"eTag": this.patientResp[ptnRespIndx].alternatePhone.eTag,
					"phoneType": phoneType,
					"number": formValue.responsibleHomeNu,
				}
			}

			let primaryEmail: any = {};
			if (this.patientResp[ptnRespIndx].primaryEmail) {
				primaryEmail = {
					"id": this.patientResp[ptnRespIndx].primaryEmail.id,
					"eTag": this.patientResp[ptnRespIndx].primaryEmail.eTag,
					"emailAddress": formValue.responsibleemail,
				}
			} else {
				primaryEmail = {
					"emailAddress": formValue.responsibleemail,
				}
			}

			let respGender: any = null;
			if (formValue.responsibleGender != "") {
				respGender = formValue.responsibleGender;
			} else {
				respGender = null;
			}

			let respPartyModel: any = {
				"id": this.patientResp[ptnRespIndx].id,
				"patientId": this.patientId,
				"contactId": this.patientResp[ptnRespIndx].contactId,
				"relationshipType": formValue.relationshiptoPatient,
				"title": formValue.responsibletitle,
				"firstName": formValue.responsiblefname,
				"middleName": formValue.responsiblemiddlename,
				"lastName": formValue.responsiblelastname,
				"nickname": formValue.responsiblePreferred,
				"gender": respGender,
				"dob": formValue.responsibledob,
				"ssn": formValue.responsibleSsn,
				"preferredContactMethod": "Email",
				"primaryAddress": primaryAddress,
				"mobilePhone": mobilePhone,
				"alternatePhone1": alternatePhone,
				"primaryEmail": primaryEmail,
				"insuranceCompanyId": formValue.responsibleInsurance,
				"employerId": formValue.responsibleEmpid,
				"employerName": formValue.responsibleEmp,
				"groupPolicyNumber": formValue.responsibleGroup,
				"individualPolicyNumber": formValue.individualPolicyNumber,
				"insuranceNotes": formValue.responsibleInsuranceNotes,
				"contactETag": this.patientResp[ptnRespIndx].contactETag,
				"eTag": this.patientResp[ptnRespIndx].eTag,
				"languageKey": formValue.languageKey,
			}

			this.populateInsuranceDataToComment(respPartyModel, formValue);

			this.locationClient.location_PutRelationship(this.selectedLocation.id, this.patientId, this.patientResp[ptnRespIndx].id, respPartyModel, null, this.patientResp[ptnRespIndx].eTag)
				.pipe(untilDestroyed(this))
				.subscribe(
					(res) => {

						if(res.contactId){
							this.addNewPolicy(res.contactId);
						}

						if (addnewresp == true) {

							/* if (this.patientResp[ptnRespIndx].primaryEmail || this.patientResp[ptnRespIndx].mobilePhone || this.patientResp[ptnRespIndx].alternatePhone) {
								this.patientRespIndx = 0;
							} else {
								this.patientRespIndx++;
							} */

							this.patientRespIndx++;

							this.accordionPanel[1] = { "title": "responsible information" };
							this.showResponsibleParty_modal = true;
							this.searchEnable = false;
							this.isLoading = false;
						} else {
							this.contactETag = '';
							this.showNotification_section(true);
							this.isLoading = false;
						}
					},
					(err) => {
						console.log(err)
					}
				)

		} else {

			let respPartyModel: any = {
				"patientId": this.patientId,
				"contactId": this.contactId,
				"relationshipType": formValue.relationshiptoPatient,
				"title": formValue.responsibletitle,
				"firstName": formValue.responsiblefname,
				"middleName": formValue.responsiblemiddlename,
				"lastName": formValue.responsiblelastname,
				"nickname": formValue.responsiblePreferred,
				"gender": formValue.responsibleGender,
				"dob": formValue.responsibledob,
				"ssn": formValue.responsibleSsn,
				"preferredContactMethod": "Email",
				"primaryAddress": null,
				"mobilePhone": null,
				"alternatePhone1": null,
				"primaryEmail": null,
				"insuranceCompanyId": formValue.responsibleInsurance,
				"employerId": formValue.responsibleEmpid,
				"employerName": formValue.responsibleEmp,
				"groupPolicyNumber": formValue.responsibleGroup,
				"individualPolicyNumber": formValue.individualPolicyNumber,
				"insuranceNotes": formValue.responsibleInsuranceNotes,
				"contactETag": this.contactETag,
				"languageKey": formValue.languageKey
			}

			this.populateInsuranceDataToComment(respPartyModel, formValue);

			this.locationClient.location_PostRelationship(this.selectedLocation.id, this.patientId, respPartyModel, null)
        .pipe(
          take(1),
          untilDestroyed(this))
				.subscribe(
					(res) => {

						if(res.contactId){
							this.addNewPolicy(res.contactId);
						}

						this.locationClient.location_GetPatientRelationships(this.selectedLocation.id, this.patientId, undefined, undefined, null)
							.pipe(untilDestroyed(this))
							.subscribe(
								(res) => {
									this.patientResp = JSON.parse(JSON.stringify(res));
									this.submitRespParty(addnewresp);
								},
								(err) => {
									console.log(err)
								}
							)
					},
					(err) => {
						console.log(err);
					}
				)

		}
	}

	searchMedicalAlert() {
		fromEvent(this.MedicalConcern.nativeElement, "keyup").pipe(
			debounceTime(500),
			filter((e: KeyboardEvent) => e.keyCode !== 40),
			filter((e: KeyboardEvent) => e.keyCode !== 38),
			map((userInput: any) => userInput.target.value)).subscribe(res => {

				let searchkeyword: any = res.toLowerCase();
				if (searchkeyword.length > 0) {
					this.filterMdclAlrtArr = this.mdclAlrtArr.filter(option => option.name.toLowerCase().includes(searchkeyword));
					if (this.filterMdclAlrtArr.length == 0) {
						this.filterMdclAlrtArr = this.mdclAlrtArr;
					}

				} else {
					this.filterMdclAlrtArr = [];
				}
			})
	}

	titleAutoComplete() {
		fromEvent(this.PatientTitle.nativeElement, "keyup").pipe(
			debounceTime(500),
			filter((e: KeyboardEvent) => e.keyCode !== 40),
			filter((e: KeyboardEvent) => e.keyCode !== 38),
			map((userInput: any) => userInput.target.value)).subscribe(res => {

				let searchkeyword: any = res.toLowerCase();
				if (searchkeyword.length > 0) {
					this.filterTitleArr = this.titleArr.filter(option => option.name.toLowerCase().includes(searchkeyword));
					if (this.filterTitleArr.length == 0) {
						this.filterTitleArr = this.titleArr;
					}

				} else {
					this.filterTitleArr = this.titleArr;
				}
			})
	}

	getMedicalAlert() {
    this.settingsClient.settings_GetMedicalAlerts(null)
      .pipe(untilDestroyed(this))
      .subscribe(
			(res) => {
				this.mdclAlrtArr = res.filter(item => {
					return item.isActive == true
				})
			},
			(err) => {
				console.log(err)
			}
		)
	}


	selectMedicalAlert(elem) {
		let selectedMedicalConcern: any = elem.option.value;
		this.MedicalConcern.nativeElement.value = `${selectedMedicalConcern.name}`;

		this.newPratientform.patchValue({
			medicalAlert: selectedMedicalConcern.name
		})
	}

	getProfessionals() {
    this.dentistClient.dentist_GetDentists(null, true, false, 100, undefined, null)
      .pipe(untilDestroyed(this))
      .subscribe(
			(resp) => {

				this.professionalList = resp.filter(item => {
					return item.isActive;
				})

				//console.log("dentist: ", resp)

			},
			(err) => {
				console.log(err)
			}
		)
	}

	addProfessional(elem) {
		this.selectedProfesnl = elem
		this.RefferingProfsnl.nativeElement.value = `${elem.firstName} ${elem.lastName}`;
	}

	addProfesnlToForm() {
		this.newPratientform.patchValue({
			referringProfessional: this.selectedProfesnl.id,
			referringClinicId: this.selectedProfesnl.clinicId
		});

		this.RefferingProfsnl.nativeElement.value = "";
		this.showProfessnlPopup = false;

		this.professionalthank.nativeElement.value = `${this.selectedProfesnl.firstName} ${this.selectedProfesnl.lastName}`;

	}

	enablePrfsnlPopup() {
		this.showProfessnlPopup = true;
	}

	removeProfesnl() {
		this.newPratientform.patchValue({
			referringProfessional: null,
			referringClinicId: null
		})

		this.selectedProfesnl = {};
		this.RefferingProfsnl.nativeElement.value = "";
		this.showProfessnlPopup = false;
		this.professionalthank.nativeElement.value = "";
	}

	closeProfsnlPopup() {
		this.RefferingProfsnl.nativeElement.value = "";
		this.selectedProfesnl = {};
		this.showProfessnlPopup = false;
	}

	enablePatientPopup() {
		this.showPatientlPopup = true;
	}

	closePatientPopup() {
		this.RefferingPatient.nativeElement.value = "";
		this.selectedProfesnl = {};
		this.showPatientlPopup = false;
	}

	addRefferingPatient(elem) {
		this.selectedReferringPatient = elem
		this.RefferingPatient.nativeElement.value = `${elem.firstName} ${elem.lastName}`;
	}

	addRefferingPatientToForm() {
		this.newPratientform.patchValue({
			referringPatient: this.selectedReferringPatient.id
		});
		this.RefferingPatient.nativeElement.value = '';
		this.showPatientlPopup = false;
		this.patientthank.nativeElement.value = `${this.selectedReferringPatient.firstName} ${this.selectedReferringPatient.lastName}`;

	}

	removeRefferingPatient() {
		this.newPratientform.patchValue({
			referringProfessional: null,
			referringClinicId: null
		})

		this.selectedReferringPatient = {};
		this.RefferingPatient.nativeElement.value = "";
		this.showPatientlPopup = false;
		this.patientthank.nativeElement.value = "";
	}

	getEmployers() {
    this.settingsClient.settings_GetEmployers()
      .pipe(untilDestroyed(this))
			.subscribe(res => {
				this.employers = res.filter(item => item.isActive).sort((a, b) => a.name > b.name ? 1 : -1);
			}, err => {
				console.log("settings_GetEmployers err: ", err);
			}, () => { })
	}

	getDentistSerarchValue(searchobj){
		this.professionalthank.nativeElement.value = searchobj.dentist.displayName;

		this.newPratientform.patchValue({
			referringProfessional: searchobj.dentist.id,
			referringClinicId: searchobj.clinic.id
		})
	}

	getPatientSerarchValue(searchobj){
		this.patientthank.nativeElement.value = `${searchobj.patientModel.firstName} ${searchobj.patientModel.lastName}`;
		this.newPratientform.patchValue({
			referringPatient: searchobj.patientModel.patientId
		})
	}

	searchExistingPatient(){
		let patientForm: any = this.newPratientform.value;
		let firstName: any = patientForm.patientFirstName;
		let lastName: any = patientForm.patientLastName;

		if(firstName.length > 0 && lastName.length > 0){
			this.getPatientList(firstName, lastName);
		}
	}

	getPatientList(firstName, lastName){

    this.isSearchLoading = true;

    const query = new SearchContactQueryDto();

    query.firstName = firstName;
    query.lastName = lastName;

		this._searchClient
      .search_PostContacts(query, 500, 0, true)
      .pipe(untilDestroyed(this))
		  .pipe(take(1))
		  .subscribe(
			(res) => {
				this.searchExistingPatientArr = res;
        if(this.searchExistingPatientArr.length > 0) {
          this.isExistingPatient = true;
        } else {
          this.isExistingPatient = false;
        }

        this.isSearchLoading = false;
			},
			(err) => {
				this.isSearchLoading = false;
				console.log('searchPatient error:', err)
			}
		  );

	}

	closeSearchPanel(){
		this.isExistingPatient = false;
		this.searchExistingPatientArr = []
	}

	getPatientDentist(searchobj){
		this.patientsDentist.nativeElement.value = searchobj.dentist.displayName;
		this.newPratientform.patchValue({
      dentistId: searchobj.dentist.id,
      dentistClinicId: searchobj.clinic.id
		})
	}

	insuranceCompanySelected(id: number) {
		let ins = this.insuranceCompies.find((ic) => ic.id == id);

		console.log("ins", ins)

		this.newResponsiblePartyform.patchValue({
			"responsibleInsuranceName": ins.shortName,
			"responsibleInsurance": ins.id,
			"payorId": ins.payorNumber
		})
	}

	searchInsurance() {
		if (this.InsuranceInput) {
			fromEvent(this.InsuranceInput.nativeElement, "keyup").pipe(
				debounceTime(500),
				filter((e: KeyboardEvent) => e.keyCode !== 40),
				filter((e: KeyboardEvent) => e.keyCode !== 38),
				map((userInput: any) => userInput.target.value)).subscribe(res => {

					let searchkeyword: any = res.toLowerCase();
					if (searchkeyword.length > 0) {
						this.filterInsuranceArr = this.insuranceCompies.filter(option => (option.name.toLowerCase().includes(searchkeyword) || option.shortName.toLowerCase().includes(searchkeyword)));
						if (this.filterInsuranceArr.length == 0) {
							this.filterInsuranceArr = this.insuranceCompies;
						}
					} else {
						this.filterInsuranceArr = this.insuranceCompies;
					}
					this.filterInsuranceArr = _.orderBy(this.filterInsuranceArr, [inusrance => inusrance.shortName.toLowerCase()], ['asc']);
					this.insuranceSortType = 'asc';
				})
		}
	}

	sortInsurance(sortOn) {
		this.insuranceSortType = this.insuranceSortType == 'asc' ? 'desc' : 'asc';
		if (sortOn == 'shortname') {
			this.filterInsuranceArr = _.orderBy(this.filterInsuranceArr, [inusrance => inusrance.shortName.toLowerCase()], [this.insuranceSortType]);
		} else {
			this.filterInsuranceArr = _.orderBy(this.filterInsuranceArr, [inusrance => inusrance.name.toLowerCase()], [this.insuranceSortType]);
		}
	}

	getRespPartyData(){
		this.locationClient.location_GetPatientRelationships(this.selectedLocation.id, this.patientId, undefined, undefined, null)
		.pipe(untilDestroyed(this))
		.subscribe(res => {
			this.patientResp = res;
			this.getSingleRelationship();

		})
	}

	getInsurnaceCompanyById(id){
		let insComp = this.insuranceCompies.find((ic) => ic.id == id);
		return insComp;
	}

	addPolicy(){
		this.showAddPolicyBtn = false;
		this.showInsuranceSection = true;
	}

	selectPolicy(policy){
		this.selectedPolicy = policy;
	}

	addNewPolicy(contactId){
		let respFormValue = this.newResponsiblePartyform.value;
		if(respFormValue.effectivedate && respFormValue.responsibleInsurance){
			let policyModel: PolicyDto = new PolicyDto();
			policyModel.activeFromDate = respFormValue.effectivedate;
			policyModel.contactId = contactId;
			policyModel.employerId = respFormValue.responsibleEmpid;
			policyModel.employerName = respFormValue.responsibleEmp;
			policyModel.groupPolicyNumber = respFormValue.responsibleGroup;
			policyModel.individualPolicyNumber = respFormValue.individualPolicyNumber;
			policyModel.insuranceCompanyId = respFormValue.responsibleInsurance;
			policyModel.notes = respFormValue.responsibleInsuranceNotes;

			this.contactClient.contact_PostPolicy(contactId, policyModel).subscribe(resp => {})
		}
	}

	getDate() {
		if (this.selectedPolicy) {
			let dt = moment(this.selectedPolicy.activeFromDate).tz('UTC').format('MM/DD/YYYY');
			if (dt) {
				return dt;
			}
		}

		return null;
	}


}

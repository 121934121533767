import { Component, OnInit, OnDestroy, Inject, ViewEncapsulation, ViewChild, TemplateRef, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { catchError, take, takeUntil } from 'rxjs/operators';
import { base64ToFile } from 'ngx-image-cropper';
import { CarouselComponent } from 'angular-responsive-carousel';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FillTypeEnum, FromTypeEnum, StorageItemBaseDto, TwainDto } from '../../../../shared/services/api.service';
import { RootStoreState, TwainStoreActions, TwainStoreSelectors } from '../../../../root-store';
import { DownloadService } from '../../../../shared/services/download/download.service';
import { AdvancedEditorActions, AdvancedEditorData } from '../../../../shared-module/advanced-editor/AdvancedEditorModels';
import { AdvancedEditorComponent } from '../../../../shared-module/advanced-editor/advanced-editor.component';

@Component({
  selector: 'image-builder',
  templateUrl: './image-builder.component.html',
  styleUrls: ['./image-builder.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ImageBuilderComponent implements OnInit, OnDestroy {
  @ViewChild('carouselHeader', { static: false }) carouselHeader: CarouselComponent;
  @ViewChild('carouselBody', { static: false }) carouselBody: CarouselComponent;

  @Input() isUseThumbnail: boolean;

  fromTypeEnum = FromTypeEnum;
  selectedFromType: FromTypeEnum = FromTypeEnum.LocalFile;
  twains$ = this._store$.select(TwainStoreSelectors.selectAllTwain);
  isWorking: boolean = false;
  isDownloadingTwain: boolean = false;
  isDownloadingPatientFile: boolean = false;
  hasImageSetToHeadings: boolean = false;
  today: Date = new Date;
  validComboDrag: any;
  selectedImageIndex: number = null;
  deleteAllConfirmMessage: string = `<span style=\"color: #FFFFFF\">If you are sure, I will</span>
    <br>delete ALL images<br>
    <span style=\"color: #FFFFFF\">on the second row.</span>`;

  deleteConfirmMessage: string = `<span style=\"color: #FFFFFF\">If you are sure, I will</span>
    <br>delete this image<br>
    <span style=\"color: #FFFFFF\">on the second row.</span>`;

  images: FileUploadModel[] = [];
  dragImages: File[] = [];
  imageBuilderHeadings: ImageBuilderHeading[] = [];
  editedImageBuilderHeading: ImageBuilderHeading;

  allowedType: string[] = [
    'image/tif',
    'image/tiff',
    'image/jpeg',
    'image/jpg',
    'image/png'
  ];

  private _destroy$: Subject<boolean> = new Subject<boolean>();
  @ViewChild('existImageDialog', { static: false }) existImageDialog: TemplateRef<any>;

  constructor(
    private _store$: Store<RootStoreState.State>,
    private _dialogRef: MatDialogRef<ImageBuilderComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: ImageBuilderHeading[],
    private _downloadService: DownloadService,
    private _snackBar: MatSnackBar,
    private matDialog: MatDialog,
    private sanitizer: DomSanitizer
  ) {
    if (_data) {
      this.imageBuilderHeadings = _data;
    }
  }

  ngOnInit() {
    this.initializeData();
  }

  ngAfterViewInit(){
  }

  ngOnDestroy() {
    this._destroy$.next(true);
  }

  initializeData(): void {
    //this.isWorking = true;
  }

  cancel(): void {
    this._dialogRef.close();
  }

  clearUploadImages(): void {
    this.images = [];
    this.selectedImageIndex = null;
  }

  save(): void {
    this._dialogRef.close({
      action: ImageBuilderActions.Save,
      images: this.imageBuilderHeadings
    })
  }

  imagesChange(): void {
    this.setImagePreview();
    this.reloadCarousel();
  }

  setImagePreview(): void {
    this.images.forEach(async (image: FileUploadModel) => {
      if (image && !image.previewImage) {
        image.previewImage = this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(image)));
      }
    });
  }

  removeImage(index: number): void {
    this.images.splice(index, 1);
    this.reloadCarousel();
  }

  setImageBuilderHeading(index: number): void {
    if (this.selectedImageIndex != null && this.images[this.selectedImageIndex]) {
      this.imageBuilderHeadings[index].image = this.images[this.selectedImageIndex];
      this.images[this.selectedImageIndex] = null;

      const reader = new FileReader();
      reader.readAsDataURL(this.imageBuilderHeadings[index].image);
      reader.onload = () => {
        this.imageBuilderHeadings[index].previewImage = this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(this.imageBuilderHeadings[index].image)));
        this.imageBuilderHeadings[index].imageBase64 = reader.result.toString();
        let nextImageIndex: number = _.findIndex(this.images, image => {
          return image != null;
        }, this.selectedImageIndex);

        if (nextImageIndex < 0)
          nextImageIndex = _.findIndex(this.images, image => {
            return image != null;
          });

        this.selectedImageIndex = nextImageIndex > -1 ?
          nextImageIndex :
          null;
        this.reloadCarousel();
      };
    }
  }

  moveToImages(index: number): void {
    if (!this.editedImageBuilderHeading && this.imageBuilderHeadings[index].image) {
      let firstOpenSpaceIndex: number = _.findIndex(this.images, image => {
        return image == null;
      });

      let moveToImageFile = this.imageBuilderHeadings[index].image;

      if (firstOpenSpaceIndex === -1) {
        if (this.images == null)
          this.images = [];

        this.images.push(moveToImageFile);
        this.selectedImageIndex = this.images.length - 1;
      }
      else {
        this.images[firstOpenSpaceIndex] = moveToImageFile;
        this.selectedImageIndex = firstOpenSpaceIndex;
      }

      this.imageBuilderHeadings[index].image = null;
      this.imageBuilderHeadings[index].previewImage = null;

      this.setImagePreview();
      this.reloadCarousel();
    }
  }

  showExistingImageWarning(index): void {
    let existingImageWarningDialog = this.matDialog.open(this.existImageDialog, { width: '470px' });
    existingImageWarningDialog.afterClosed().pipe(take(1)).subscribe(result => {
      if (result) {
        this.setImageBuilderHeading(index);
      }
    })
  }

  ImageEdited(imageBuilderHeading: ImageBuilderHeading, result: any): void {
    this.imageCropped(imageBuilderHeading, result.base64);
    imageBuilderHeading.height = result.height ? result.height : 100;
    imageBuilderHeading.width = result.width ? result.width : 100;
    imageBuilderHeading.fillType = result.fillType;
  }

  imageCropped(imageBuilderHeading: ImageBuilderHeading, base64: string): void {
    imageBuilderHeading.imageBase64 = base64;
    imageBuilderHeading.image.data = base64ToFile(base64);
    imageBuilderHeading.previewImage = this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(imageBuilderHeading.image.data)));
    imageBuilderHeading.image.fileName = imageBuilderHeading.image.name;
  }

  async urlToFile(name: string, imageUrl: string): Promise<File> {
    let response = await fetch(imageUrl);
    let data = await response.blob();
    let metadata = {
      type: 'image/jpeg'
    };
    return new File([data], "test.jpg", metadata);
  }

  setAllImageBuilderHeading(): void {
    if (this.imageBuilderHeadings.length > 0 && this.images.length > 0) {
      _.forEach(this.images, (image, i) => {
        if (this.imageBuilderHeadings[i] && this.imageBuilderHeadings[i].image == null && image != null) {
          this.imageBuilderHeadings[i].image = image;
          this.images[i] = null;
        }
      });

      let firstImageIndex: number = _.findIndex(this.images, image => {
        return image != null;
      });

      this.selectedImageIndex = firstImageIndex > 0 ?
        firstImageIndex :
        null;
      this.reloadCarousel();
    }
  }

  private reloadCarousel(): void {
    this.checkImageBuilderHeading();

    if (this.carouselHeader)
      this.carouselHeader.onDomChanges();

    if (this.carouselBody)
      this.carouselBody.onDomChanges();
  }

  private checkImageBuilderHeading(): void {
    let hasImageIndex = _.findIndex(this.imageBuilderHeadings, (imageBuilderHeading) => {
      return imageBuilderHeading.image;
    });

    this.hasImageSetToHeadings = hasImageIndex > -1;
  }

  pickTwain(twain: TwainDto): void {
    if (this.isDownloadingTwain || !twain) return;
    if (_.findIndex(this.images, { 'twainId': twain.id }) > -1) {
      this._snackBar.open("Twain already selected.", "Ok", {
        duration: 3000,
      });
      return;
    };

    this.isDownloadingTwain = true;
    this._downloadService.blob(twain.locationUrl, twain.name)
      .pipe(
        take(1),
        takeUntil(this._destroy$),
        catchError((error) => {
          this._snackBar.open("Invalid Image.", "Ok", {
            duration: 3000,
          });
          this.isDownloadingTwain = false;
          return null;
        })
      )
      .subscribe((blob: Blob) => {
        let file: FileUploadModel = new File(
          [blob],
          twain.name,
          { type: blob.type });

        file.fromType = FromTypeEnum.Twain;
        file.twainId = twain.id;

        this.images.push(file);
        this.setImagePreview();
        this.reloadCarousel();
        this.isDownloadingTwain = false;
      })
  }

  loadTwain(): void {
    this._store$.dispatch(TwainStoreActions.LoadRequest({}));
  }

  patientFileSelected(patientFile: StorageItemBaseDto): void {
    if (this.isDownloadingPatientFile) return;
    if (!patientFile || !patientFile.locationContentType) {
      this._snackBar.open("Invalid Image.", "Ok", {
        duration: 3000,
      });
      return;
    }

    if (!_.includes(this.allowedType, patientFile.locationContentType)) {
      this._snackBar.open("Invalid Image.", "Ok", {
        duration: 3000,
      });
      return;
    }

    this.isDownloadingPatientFile = true;
    const selectedFileUrl = this.isUseThumbnail ? patientFile.locationThumbnailUrl : patientFile.locationUrl;
    this._downloadService.blob(selectedFileUrl, patientFile.name)
      .pipe(
        take(1),
        takeUntil(this._destroy$),
        catchError((error) => {
          this._snackBar.open("Invalid Image.", "Ok", {
            duration: 3000,
          });
          this.isDownloadingPatientFile = false;
          return null;
        })
      )
      .subscribe((blob: Blob) => {
        let file: FileUploadModel = new File(
          [blob],
          patientFile.name,
          { type: blob.type });

        file.fromType = FromTypeEnum.PatientFile;

        this.images.push(file);
        this.setImagePreview();
        this.reloadCarousel();
        this.isDownloadingPatientFile = false;
      })
  }

  async pasteFromClipboard(): Promise<void> {
    let anyNavigator: any = window.navigator;
    const clipboardContents = await anyNavigator.clipboard.read();
    for (const item of clipboardContents) {
      let imageType: string;
      item.types.some(type => {
        if (type.startsWith('image/')) {
          imageType = type
        }
      })

      if (imageType) {
        const blob = await item.getType(imageType);
        let file: FileUploadModel = new File(
          [blob],
          'clipboardImage',
          { type: blob.type });

        file.fromType = FromTypeEnum.LocalFile;

        this.images.push(file);
        this.setImagePreview();
        this.reloadCarousel();
      }
      else {
        this._snackBar.open("Latest clipboard data is not an image.", "Ok", {
          duration: 3000,
        });
      }
    }
  }

  editImageBuilderHeading(imageBuilderHeading: ImageBuilderHeading): void {
    this.editedImageBuilderHeading = imageBuilderHeading;

    let advancedEditorData: AdvancedEditorData = {
      image: this.editedImageBuilderHeading.image,
      originalImage: this.editedImageBuilderHeading.image,
      imageTransform: null,
      originalImageTransform: null,
      enableReloadOriginal: false,
      width: this.editedImageBuilderHeading.width,
      height: this.editedImageBuilderHeading.height,
      enableResize: true,
      fillType: this.editedImageBuilderHeading.fillType
    }

    const dialogRef = this.matDialog.open(
      AdvancedEditorComponent,
      {
        data: advancedEditorData,
        disableClose: true,
        position: {right: '0px'}
      }
    );

    dialogRef.afterClosed()
      .pipe(
        take(1),
        takeUntil(this._destroy$)
      )
      .subscribe((result) => {
        if (result && result.action) {
          switch (result.action) {
            case AdvancedEditorActions.Save: {
              this.ImageEdited(this.editedImageBuilderHeading, result);
              break;
            }
            case AdvancedEditorActions.Cancel: {
              break;
            }
          }
        }
        this.editedImageBuilderHeading = null;
      });

    dialogRef.componentInstance.editedImage
      .pipe(
        takeUntil(this._destroy$)
      )
      .subscribe((result: string) => {
        this.imageCropped(this.editedImageBuilderHeading, result);
      });
  }
}

export interface ImageBuilderHeading {
  image: any,
  imageBase64?: string
  id: number,
  defaultImage?: string
  previewImage?: SafeUrl,
  isShowEdit?: boolean,
  height?: number,
  width?: number,
  fillType?: FillTypeEnum
}

export interface FileUploadModel extends File {
  fromType?: FromTypeEnum,
  twainId?: number,
  previewImage?: SafeUrl
}

export enum ImageBuilderActions {
  Cancel = 'Cancel',
  Save = 'Save',
}


